.course{
    &-list{
        width: 100%;
        .card{
            &__list{
                margin-bottom: 6.5rem;
                @include setMinRwd{
                    padding: 0rem;
                    margin-bottom: 4rem;
                }
            }

            &__info{
                @include setMinRwd{
                    padding-top: 1.25rem;
                    padding-left:2.5rem;
                    padding-right:2.5rem;
                }
            }

            &__image{
                @include setMaxRwd{
                    margin-top: 0px;
                }
            }

            &__des {
                @include setMinRwd{
                    display: block;
                    margin-top: 0px;
                    margin-bottom: 0;
                }

                @include setMaxRwd{
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }

            &__btn {
                width: 100%;
                left: 50%;
                transform: translateX(-50%);
                @include setMinRwd{
                    bottom: -6%;
                }
            }
        }
    }
}