@use "sass:math";

@import url('//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap');
// @import url('//fonts.googleapis.com/css2?family=Montserrat&display=swap');
// @import url('//fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@import url('//fonts.googleapis.com/css2?family=Inter:wght@200&display=swap');
@import url('//fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');
@import url('//fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');


$colorBg: #e60819;
$colorMainBg:#383734;
$bgSecondary:#eeeeee;
$colorBlack: #000;
$colorBack: #fff;
$colorBase: #333;
$colorMain: #007894; // 主色
$colorItem: #42818f; // 項目顏色
$colorSubs: #0097d8; // 輔色
$colorCont: #57bb3f; // 輔色2
$colorSubsCont: #374a6f; // 輔色2
$colorText: #2d456c; // 內容文字顏色
$colorList:#0c3766; // 列表文字顏色
$colorSecondary:#0160f8;
$colorErro: #BC2837;
$colorSucc: #339C3F;
$colorWarr: #E99A2C;
$colorInfo: #32abea;
$colorFB: #3b5998;
$colorLINE: #00BA01;
$colorTWIT: #1DA1F2;
$colorFooter: #143b7b;

// 顏色
$c_font: #202020;
$c_fontholder: #6B7280;
$c_red: #FF0000;
$c_white: #fff;
$c_main: #676767;
$c_subMain: #9CA3AF;
$c_lighten: lighten($c_main, 20%);
$c_grey: #AEAEAE;
$c_border: #EEEEEE;

$fzBase:16px;
$fzSubtit:18px;
$fzContent:20px;
$fontNotosanc-tc : 'Noto Sans TC';
$MontserratLight: 'Montserrat';
$RobotoThin: 'Roboto';
$Inter:'Inter';

// End

// 基本字形
%baseFont {
    color: $colorBlack;
    font-size: $fzBase;
    font-family: $fontNotosanc-tc, 'Microsoft JhengHei', '微軟正黑體', 'Arial', sans-serif;
    -webkit-text-stroke: 0.1px;
    line-height: 1.8;
    font-weight:400;
}

// 特殊自行
%Europa-Bold{
    font-family: europa, sans-serif;
    font-weight: 700;
    font-style: normal;
}


$cubic-FiSo: cubic-bezier(.37,
.99,
.92,
.96); //快進場緩停
$cubic-SiRo: cubic-bezier(.31,
1.26,
.19,
1.11); //快速回彈
$cubic-SiMo: cubic-bezier(.3,
1,
.94,
1.1); //緩進微彈
$sh_sans: 'source-han-sans-traditional',
'微軟正黑體',
sans-serif; // 無襯線(思源黑體)
$sh_serif:'source-han-serif-tc',
serif; // 襯線(思源宋體)
//google Font
$nt_sans: 'Arial',
'Noto Sans TC',
'微軟正黑體',
sans-serif; //思源黑體
$Roboto: 'Roboto'; //Roboto
$RozhaOne: 'Rozha One';
$Mont: 'Montserrat',
sans-serif; //Montserrat
// popup
$popup-width: 800px;
$popup-height: 900px;
//最大寬度
@mixin maxWidth($size) {
    width: 100%;
    max-width: $size;
    margin: auto;
}

// 區塊繼承設定
%pm0 {
    margin: 0px;
    padding: 0px;
}

%setBack {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}

%setFlex {
    display: flex;
    flex-wrap: wrap;
}

%setFlex-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center
}

%border-bDashed {
    border-bottom: 1px dashed #888;
}

%formGroup-style {
    border: #dcdcdc 1px solid;
    margin-top: 1rem;
}

// 文字大小
@function calculateRem($size) {
    @return math.div($size, 16px) * 1rem;
}

@mixin fontSize($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

@function get-vw($target) {
    $vw-context: (1900 * 0.01) * 1px;
    @return math.div($target, $vw-context) * 1vw;
}

@function get-vw-per($target) {
    $vw-context: (1900 * 0.01) * 1%;
    @return math.div($target, $vw-context) * 1vw;
}

@function get-vw-mobile($target) {
    $vw-context: (640 * 0.01) * 1px;
    @return math.div($target, $vw-context) * 1vw;
}

@function vmaxCalc($vmax) {
    @return calc(#{$vmax} * (#{1vw} + #{1vh} - #{1vmin}));
}
// usage: width: vmaxCalc(75);
// result: width: calc(75 * (1vw + 1vh - 1vmin));
// which is the same as: width: 75vmax;

//Browser compatibility
@mixin setBC($k, $v) {
    -webkit-#{$k}: $v;
    -moz-#{$k}: $v;
    -ms-#{$k}: $v;
    -o-#{$k}: $v;
    #{$k}: $v;
}

@mixin setBack($rep:no-repeat, $pos:50% 50%, $siz:cover) {
    background: {
        repeat: $rep;
        position: $pos;
        size: $siz;
    }
}

@mixin setBackSet($position,$size) {
    background-repeat: no-repeat;
    background-position: $position;
    background-size: $size;
}

@mixin setSize($w:0, $h:0) {
    width: $w;
    height: $h;
}

@mixin setProportion($proportion: 100%, $bs: contain) {
    // 等比例區塊
    background-size: $bs;
    &::before {
        content: '';
        display: block;
        padding-top: $proportion;
        @content;
    }
}

// 分配字距justify
@mixin setJustify() {
    text-align: justify;
    text-justify: inter-ideograph;
    &::after {
        content: "";
        display: inline-block;
        width: 100%;
    }
}

//Delay Animate
@mixin setDelay($ty,
$e,
$tv,
$btv: 0,
$s: 1) {
    @for $i from $s through $e {
        &:nth-child(#{$i}) {
            #{$ty}: #{$btv + $i * $tv}s;
            @content;
        }
    }
}

@mixin setStr($el,
$ty,
$baseStr,
$e,
$attcStr: '',
$s: 1) {
    @for $i from $s through $e {
        &:nth-child(#{$i})#{$el} {
            #{$ty}:#{$baseStr}#{$i}#{$attcStr};
            @content;
        }
    }
}

// 設定進場：未進場樣式, 進場樣式, 動畫曲線(預設)
@mixin setIntoView($basc, $show, $cubic: .85s $cubic-SiRo) {
    @each $k,
    $v in $basc {
        #{$k}: $v;
    }
    transition: $cubic;
    &.animation {
        @each $k,
        $v in $show {
            #{$k}: $v;
        }
        @content;
    }
}

// 隱藏文字
@mixin hide-text {
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
}

@mixin ellipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin borderStyle($style) {
    border-style:$style;
}

@mixin borderRadius($num) {
   border-radius: $num+px;
}

@mixin radius($num) {
    border-radius:$num;
}

@mixin border($width,$color,$style) {
    border-width: $width;
    border-color: $color;
    border-style: $style;
}

// 文字高度
@mixin txtHeight($num) {
    min-height: $num;
    overflow: hidden;
}

.noJS>div,
.noJS>ul {
    display: none;
}

.noJS>noscript {
    display: block;
    width: 100%;
    line-height: 10em;
}
// End
