.nav-header{
  position: sticky;
  top: 0;
  z-index: 1001;
  background: #fff;
  @include setMinRwd{
    display: flex;
    align-items: center;
    .nav-fostrap{
      flex: 1
    }
    .nav-right{
      flex-basis: 630px;
      padding-right: 10px;
    }
    &.active{
      box-shadow: 0 0 10px rgba(0,0,0,0.25);
    }
  }

  @include setWidthRwd(1651px,1890px){
    .nav-right{
      flex-basis: 500px;
    }
  }

  @include setWidthRwd(1100px,1650px){
    .nav-fostrap{
      display: block;
      ul {
        display: flex;
        justify-content: space-between;
      }
    }

    .nav-right {
      position: absolute;
      right: 10px;
      width: 630px;
      top: 1.25rem;
    }
  }

  @include pad1080{
    &{
      padding: 1rem;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    }
    .nav-right{
      display: none;
      flex-direction: column;
      &.visible{
        display: flex;
        position: fixed;
        background: #fff;
        width: 100%;
        padding: 1rem 0.25rem;
        left: 0;
        top: 5rem;
        z-index: 2;
      }
      .input-link{
        margin-top: 10px;
        justify-content: space-around;
      }
      .input-group .icon {
        display: none;
      }
    }
  }
}

.nav-fostrap {
  @extend %setFlex-center;
  justify-content: space-between;
  background: $colorBack;
  
  $border-width: 1px;

  ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: block;
    z-index:2;
    @include setMinRwd{
      padding-left: 4rem;
    }
    @include setWidthRwd(1100px,1600px){
      padding-left: 0;
    }

    &>li {
      list-style-type: none;
      margin: 0;
      display: inline-block;
      position: relative;

      &>a {
        position: relative;
        z-index: 1;
        @include fontSize(20.5px);
        color: $colorBlack;
        display: inline-block;
        outline: 0;

        background: none;
        border: none;
        padding: 0.1rem 0.6em;
        transition: color 0.2s;
        transform: translateZ(0);
        overflow: hidden;

        @include setMinRwd{
          &:before,
          &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: $border-width solid transparent;
            will-change: transform;
            transition: transform .3s ease;
            pointer-events: none;
            transform: translateZ(0);
            border-radius: 20px;
          }
          
          &:before{
            border-color: $colorMain transparent;
            transform: skew(-86deg,0)
          }
          
          &:after{
            border-color: transparent $colorMain;
            transform: skew(0,40deg);
            transition: transform .4s;
          }
        }

        @include setWidthRwd(1100px,1650px){
          @include fontSize(18.5px);
          padding-left: 2.6rem;
          padding-right: 2.6rem;
          &+ul{
            li{
              a{
                padding-left: 1rem;
                padding-right: 1rem;
              }
            }
          }
        }

        @include setWidthRwd(1100px,1250px){
          padding-left: 2.15rem;
          padding-right: 2.15rem;
        }
      }
      
      &:hover,
      &.active{
        a {
          color: $colorMain;
          &:before,
          &:after{
            transform: skew(0,0)
          }
        }
      }
      
      &:hover {
        ul.dropdown {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &-link{
    display: flex;
    align-items: center;
    flex: auto;

    @include pad1080{
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
      ul{
        width: 100%;
      }
    }
  }

  @include setMinRwd{
    padding: 0.5rem 1rem 0.75rem;
    li {
      padding: 0.5rem 0.25rem 0;
    }
  }

  @include setWidthRwd(1005px,1699px){
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include setWidthRwd(1005px,1200px){
    li{
      a{
        font-size: 16px;
      }
    }
  }

}

.dropdown {
  width: 100%;
  min-width: 100px;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  padding-top: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  text-align: center;
  background-color: #fff;
  border:#898989 1px solid;
  padding: 3px !important;
  display: block !important;

  &::before,
  &::after{
    content: '';
    width: 0;
    height: 0;
    border-bottom: #898989 10px solid;
    border-top: transparent 10px solid;
    border-left: transparent 10px solid;
    border-right:transparent 10px solid ;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  &::before{
    border-bottom-color:#fff;
    top: -19px;
    z-index: 2;
  }

  &.active{
    display: block !important;
    opacity: 1;
    visibility: visible;
  }

  li {
    padding: 0.5rem 0.25rem 0 !important;
    &::before{
      display: none;
    }

    &,
    a{
      display: block;
      width: 100%;
    }

    a {
      padding: 0.65rem 0.3rem;
      font-weight: 400;
      color: #000;
      line-height: 1.5;
      font-size: 17px !important;
      &::after{
        display: none;
      }
      &::before,
      &:after{
        display: none !important;
      }
    }
    &:hover{
      background-color: #E5E5E5;
    }
  }

  @include setMaxRwd{
    position: static;
    display: none !important;
    border: none;
    margin-left: 0;
    margin-top: 2px;
    transform: translateX(0);
    &::before,
    &::after{
      display: none;
    }

    &.active{
      display: block !important;
      opacity: 1;
      visibility: visible;
    }

    li {
      padding: 0;
      a {
        border-bottom: none;
        text-align: left;
      }
    }
  }
}

.nav-fostrap-menuBg{
  position: absolute;
  left: 0;
  top: 5rem;
  @include setSize(100%,65px);
  background: rgba(#d4e8ce,.37);
  z-index: 1;
  display: none;
  &.active{
    display: block;
  }
}

.arrow-down {
  position: absolute;
  right: 1rem;
}

.title-mobile {
  display: none;
}

@include setStyleMinRwd(1025px) {
  .container {
    width: 96%;
  }
  
  .nav-bg-fostrap,
  .arrow-down {
    display: none;
  }

}

@include setWidthRwd(1601px, 1750px){
  .nav-logo{
    img{
      max-width: 15vw;
    }
  }
}

@include setWidthRwd(1025px, 1200px){
  .nav-logo{
    display: block;
    width: 30vw;
  }
}

@include pad1080{
  .nav-fostrap {
    background: #fff;
    @include setSize(45vw,100%);
    display: block;
    position: fixed;
    left: -100%;
    top: 0px;
    transition: left 0.25s ease;
    z-index:12;
    li{
      a{
        &::after{
          display: none !important;
        }
      }
    }
  }

  .title-mobile {
    display: block;
    max-width: 50vw;
  }

  .nav-logo{
    display: block;
    margin: auto;
  }

  .mobile-closeBtn{
    @include setSize(40px,40px);
    background-color: #000;
    text-align: center;
    color:$colorBack;
    position: absolute;
    right: -40px;
    top: -74px;
    z-index: 6;
    @include fontSize(25px);
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      @include setSize(100%,100%);
    }
  }

  .nav-fostrap{
      &.visible {
          left: 0px;
          transition: left 0.25s ease;
      }
  }

  .nav-bg-fostrap {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 65px;
    margin: 0;
    // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    z-index: 1;
  }

  .navbar-fostrapClick {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    padding: 12px;

    span {
      height: 2px;
      background: $colorMain;
      margin: 3px 5px;
      display: block;
      width: 20px;

      &:nth-child(2),
      &:nth-child(3) {
        width: 20px;
      }
    }

    .txt{
      @include fontSize(14px);
      line-height: 1;
    }

    &.member{
      right: 4rem;
      display: flex;
      align-items: center;
      padding-top: 6px;
      .icon{
        max-width: 20px;
        margin-top: -6px;
      }
    }
  }

  .nav-fostrap {
    ul {
      padding-top: 5px;
    }

    li {
      display: block !important;
      border-bottom: #eee 1px solid;
      a {
        display: block !important;
        @include fontSize(16px);
        padding: 6px 12px !important;
      }

      &:first-child:hover a {
        border-radius: 0;
      }

      &:hover,
      &.active {
        a {
          background: $colorMain;
          color: #fff !important;
        }
      }

      a{
        &.active{
          background: darken($colorMain, 5%);
          color: #fff !important;
          .arrow-down{
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}


.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.nav-overlay {
  @include setSize(100%,100%);
  background: rgba(0,0,0,0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: none;
  opacity: 0;
  &.show{
      display: block;
      opacity: 1;
  }
}

// Right
.nav-right{
  display: inline-flex;

  .input{
    &-link{
      flex: none;
    }
  }

  // 
  .form-control,
  .input-group-btn{
    border-radius: 30px !important;
  }
  
  .input-group-btn{
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 0 !important;
    .btn{
      color: #fff;
      background-color: #555555;
      border-radius: 30px !important;
      padding: 0.4rem 1rem;
    }
  }

  .form-control{
    background-color: #fff;
    height: 40px;
    &+ .form-control{
      margin-left: 10px;
    }
  }
}

.input{
  &-group,
  &-link{
    padding: 7.5px 10px;
    border-radius: 40px;
    overflow: hidden;
  }

  &-group{
    align-items: center;
    background-color: #dcdcdc;
    .icon{
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  &-link{
    display: flex;
    align-items: center;
    border:#c9c9c9 1px solid;
    color:#777777;
    font-size: 14px;
    margin-left: 2px;
    a{
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}