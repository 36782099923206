.mainbanner{
    .img{
        img{
            width: 100%;
        }
    }
    .txt{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        max-width: 1450px;
        z-index: 1;
        color:#fff;
        &-big{
            @include fontSize(36px);
            margin-bottom: 10px;
        }
        &-small{
            @include fontSize(18px)
        }

        @include setStyleMaxRwd(1500px){
            padding-left: 45px;
        }
    }

    @include pad{
        .img{
            img{
                height: 200px;
                object-fit: cover;
                object-position: center;
            }
        }
        .txt{
            top: 50%;
            padding-left: 2rem;
            padding-right: 2rem;
            &-big{
                @include fontSize(25px)
            }
        }
    }
}

canvas {
    display: block;
    vertical-align: bottom;
}

#particles-js {
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
}

.content-title{
    border-bottom: 1px solid #b8b8b8;
    .wrapper{
        @extend %setFlex-center;
    }
    .item{
        font-size: 20px;
        color:#000;
        a,
        span{
            position: relative;
            display: block;
            padding: 32.5px 0 28px;
        }
        &:hover,
        &.current{
            color:$colorSubs;
            a,
            span{
                &::after{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 3px;
                    background-color: $colorSubs;
                    position: absolute;
                    bottom: 0;
                }
            }
        }
        &~.item{
            margin-left: 30px;
            &::before{
                content: '';
                display: block;
                width: 1px;
                height: 20px;
                background-color:#bfbfbf;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -6px;
            }
            a,
            span{
                margin-left: 25px;
            }
        }
        
        @include setStyleMaxRwd(1500px){
            padding-left: 45px;
            span{
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }

        @include pad{
            padding-left: 30px;
           a,span{
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }
}

