// main-banner
.main-banner{
    img{
        width: 100%;
    }
    @include mobile{
        img{
            height: 125px;
            object-fit: cover;
            object-position:left;
        }
    }
}

// 
.main-headingStyle{
    @extend %setFlex-center;
    justify-content: space-between;
    margin-bottom: 1rem;
    @include setStyleMinRwd(1600px){
        max-width: 91%;
    }
    @include setMaxRwd{
        margin-bottom: 1rem;
    }
}


// 麵包屑
.breadcrum-wrap {
    .breadcrumb {
        @include fontSize(16px);
        color: #404040;
        background-color: transparent;
        margin-bottom: .25rem;
        padding: 0.5rem 0;
        .icon-home{
            display: inline-block;
            vertical-align: top;
            margin-top: 5px;
        }
        .breadcrumb-item{
            padding-left: 0;
            &+.breadcrumb-item::before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 19px;
                background: url('../images/icon-arrow03.png') no-repeat center;
                padding-top: 1.75rem;
            }
            &.active{
                color:#323232;
            }
            @include setMinRwd{
                &+.breadcrumb-item::before {
                    padding-right: 2rem;
                }
            }
        }
    }
}

// 文字 / 背景顏色
.text-main {
    color: $colorItem !important
}

.bg-second{
    background-color:$bgSecondary !important;
}

.text-unnderLine{
    text-decoration: underline;
}

// 標題
.headingStyle{
    margin-bottom: 1.5rem;
    .title{
        font-size: 2.5rem;
    }
}

// 特用標題
.block-title-box{
    @include fontSize(45px);
    line-height: 1.25;
    margin-bottom: 1.25rem;
    z-index: 10;
    p{
        margin-bottom: 0;
    }

    .small{
        margin-bottom: 5px;
        padding-left: 5px;
        font-size: 20px;
        @include setMinRwd{
            font-size: 16px;
            margin-bottom: 10px;
        }
    }

    .txt{
        font-size: 25px;
        @include setMinRwd{
            font-size: get-vw(36px);
        }
    }

    &.titleStyle{
        flex-direction: column;
        align-items: flex-start;
        padding-left: 5.5rem;
        &::before {
            width: calc(40% + 2rem);
            position: absolute;
            top: 8px;
            left: -1rem;
            @include setMaxRwd{
                width: 5rem;
                top: 12px;
            }
        }
        @include setMinRwd{
            padding-left: get-vw(165px);
        }

        &.white{
            color:#fff;
            &::before {
                background-color: #fff;
            }
        }
    }
    
    @include mobile{
        margin-bottom: 0.25rem;
    }
}

.block-titleImg-box {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    .block-img{

    }
    .block-title {
        margin: 1rem 0rem 1rem 0.75rem;
        line-height: 1.3;
        p{ 
            margin-bottom: 5px;
        }
        .txt-small {
            color:#888787
        }
        .txt-big{
            @include fontSize(24px);
            color:#010101;
        }
    }
}

// Line Through
.titleStyle{
    position: relative;
    display: flex;
    align-items: center;

    &::before,
    &::after {
        content: '';
        display: block;
        flex: 1;
        height: 2px;
        background-color: #030303;
    }
    &::before{
        margin-right: 5px;
    }
    &::after{
        margin-left: 5px;
    }

    &-left{
        &::after{
            display: none;
        }
    }

    &-right{
        &::before{
            display: none;
        }
    }
}

.fontThrough{
    text-decoration: line-through;
}

// 邊距
@include setMinRwd{
    .block-pl85{
        padding-left: 85px;
    }
    
    .block-pl45{
        padding-left: 45px;
    }
    
    .block-pl35{
        padding-left: 35px;
    }
}

// 頁面背景色
.bg-intro{
    background-color: #eeeae9;
}

// 底線樣式
.style__underLine{
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: #fff;
    &__small{
        border-top: none;
        border-bottom-width:2px;
        border-bottom-style: dotted;
    }
}

// 項目
.tag-item {
    display: inline-block;
    width: auto;
    line-height: 1.1;
    margin: 2.5px;
    padding: 15px;
    text-align: center;
    color: white;
    background: $colorMain;
    line-height: 1.2;
    &.main {
        border: $colorSubs 1px solid;
        color: $colorSubs !important;
        font-size: 17px !important;
        padding: 8px 12px;
    }

    &.main_line {
        border-radius: 20px;
        border: $colorMain 1px solid;
        color: $colorMain !important;
        background-color: transparent;
        font-size: 17px !important;
        padding: 8px 12px;
        &.big{
            padding: 0.75rem 2rem;
            border-radius: 35px;
        }
    }

    &.main_line_dark {
        border-radius: 20px;
        border: #000 1px solid;
        color: #000 !important;
        background-color: transparent;
        font-size: 17px !important;
        padding: 10px 26px;
        &:hover{
            background-color: $colorMain;
            border-color: $colorMain;
            color:#fff !important;
        }
    }

    &.main_style{
        background-color: #739ab7;
        color: #fff;
        font-size: 18px !important;
        padding-left:2rem;
        padding-right: 2rem;
        border-radius: 35px;
        &:hover{
            border-color: $colorMain;
        }
    }

    &.main_style_line {
        border: #739ab7 1px solid;
        color: #739ab7 !important;
        background-color: #fff;
        font-size: 18px !important;
        padding-left:2rem;
        padding-right: 2rem;
        border-radius: 35px;
        &:hover{
            background-color: $colorMain;
            border-color: $colorMain;
            color:#fff !important;
        }
    }

    &.sub{
        background-color: #f0f0f0;
        color:#686767;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    &.sub_line{
        background-color: transparent;
        border-radius: 35px;
        border: #686767 1px solid;
        color:#686767;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    &.small{
        padding: 8px 10px;
    }
}

.form-control{
    border:none;
    height: 50px;
    &,
    &:focus{
        background-color: #eeeeee;
    }
}

.form-control,
.btn{
    border-radius: 0;
}

.btn{
    &:hover{
        background-color: $colorMain;
        color:#fff;
    }
}

.modal {
    text-align: center;
    padding-right: 0 !important;
    .modal-content{
        border-color: transparent;
        border-radius: 20px;
    }
}

.modal-open{
    padding-right: 0 !important;
}

// 取消訂單的警告視窗
.modal-orderCancel{
    .modal-dialog {
        max-width: 520px;
    }
    .modal-content {
        background: #eee;
    }
    .modal-header{
        border-bottom: none;
    }
    .modal-body {
        border-radius: 20px;
        background: #fff;
        margin: 0.5rem 1rem 1.5rem;
        padding: 1.5rem 0;
    }
    .close {
        font-size: 2rem;
    }
}

// 視窗內的文字區塊
.modal-center{
    @extend %setFlex-center;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    color: #020202;
    .icon{
        margin-bottom: 0.5rem;
    }
}

@include setStyleMinRwd(500px){
    .modal-dialog {
        width: 100%;
        max-width: 85rem;
    }
}

@include setWidthRwd(1100px,1500px){
    .modal-dialog {
        max-width: 70rem;
    }
}
    
.modal-autoheight{
    .modal-body {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 100px !important;
        max-height: 100vh !important;
        @include setStyleMinRwd(500px){
            max-height: calc(100vh - 50px) !important
        }
        @include pad1080{
            height: calc(90vh - 1.5rem) !important;
        }
    }
    @include pad1080{
        transform: translateY(5%);
    }
}

@include setMinRwd{
    .modal {
        text-align: center;
        padding: 0!important;
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -4px;
        }
        &-dialog {
            display: inline-block;
            text-align: left;
            vertical-align: middle;
        }
    }
}


// Checkbox & radio
.checkbox_style {
    &>input {
        &[type="checkbox"] {
            width: 100%;
            height: 30px;
            position: absolute;
            top: 0px;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
        }
        &+label {
            position: relative;
            display: flex;
            align-items: center;
            text-align: left;
            margin-top: 2px;
            margin-bottom: 5px;
            margin-right: 2px;
            margin-left: 0;
            padding-left: 1.75rem;
            cursor: pointer;
            border: none;
            &::before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                font-size: 1.5rem;
                position: absolute;
                left: 0;
                border: #000 1px solid;
                flex: none;
            }
            &::after {
                content: "\f00c";
                font-family: FontAwesome;
                position: absolute;
                left: 2px;
                font-size: 1rem;
                opacity: 0;
            }
        }
        &:checked+label::after {
            opacity: 1;
        }
    }
    
    p {
        margin-top: 10px;
    }

    &_gray{
        &>input{
            &+label{
                &::before {
                    width: 30px;
                    height: 30px;
                    border: #b5b5b5 1px solid;
                    background-color: #b5b5b5;
                    border-radius: 4px;
                }
                &::after {
                    left: 3px;
                    font-size: 1.5rem;
                    color: #fff;
                }
            }
        }
    }
}

.radio_style {
    @extend %setFlex-center;
    margin-top: 10px !important;
    input {
        &[type="radio"] {
            display: none;
        }
        &+label {
            position: relative;
            display: flex;
            align-items: center;
            line-height: 1;
            margin-bottom: 0;
            &::before,
            &::after {
                content: "";
                display: inline-block;
                border-radius: 50%;
            }
            &::before {
                flex: none;
                width: 20px;
                height: 20px;
                border: #cecece 1px solid;
                background: #fff;
                vertical-align: middle;
                margin-right: 6px;
            }
            &::after {
                width: 10px;
                height: 10px;
                background-color: transparent;
                position: absolute;
                left: 5px;
                top: 50%;
                transform: translate(0%, -50%);
            }
        }
        &:checked {
            &+label {
                &::after {
                    background-color: $colorText;
                }
            }
        }
    }
    @include setMinRwd {
        &~.radio_style {
            margin-left: 1rem;
        }
    }
}

// 按鈕
.btn-wrap {
    @extend %setFlex-center;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    margin: 2rem 0 1rem;
    &>li {
        flex: 1;
        &+li {
            margin-left: 1.5rem;
        }
    }
    @include pad1080 {
        justify-content: center !important;
        margin-top: 1rem;
        &>li {
            &+li {
                margin-left: 0.5rem;
            }
        }
    }
}

.btn{
    padding-top: 0.65em;
    padding-bottom: 0.65em;
    font-size: 18px !important;
    &-main{
        background-color: $colorMain;
        border-color: $colorMain;
        border-radius: 20px;
    }
}



.btn_style {
    border-radius: 5px;
    background: $colorMain;
    display: block;
    color: $c_white !important;
    font-size: 15px;
    line-height: 15px;
    padding: 12px 25px;
    transition: background .4s;

    &:hover {
        background: $c_font;
    }

    &.active {
        background: $c_main;
        &:hover {
            background: $colorBlack;
        }
    }

    &.prev {

        i {
            margin-right: 10px;
        }
    }

    &.next {
        background: $c_main;

        &:hover {
            background: $c_font;
        }

        i {
            margin-left: 10px;
        }
    }
}

// 左側連結
.sidemenu_ul{
    &>li {
        list-style: none;
        width: 100%;
        margin-bottom: 10px;
        &>a {
            color:#676666;
            @include fontSize(18px);
            line-height: 1.5;
            display: block;
            position: relative;
            width: 100%;
            padding: 10px;
            text-align: center;
            &::after {
                content: '';
                display: inline-block;
                width: 100%;
                height: 100%;
                border-radius: 30px;
                border: #3e3e3e 1px solid;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                transition: 0.25s;
                opacity: 0;
            }
            &:hover{
                &::after {
                    opacity: 1;
                }
            }
        }
        
        &>.first_menu{
            display: none;
            &>li {
                list-style: none;
                padding:15px 10px;
                margin-bottom: 2px;
                &> a {
                    @include fontSize(18px);
                    color: #7f7f7f;
                    display: block;
                    padding-left: 20px;
                    position: relative;
                    line-height: 1;
                }
            }
        }
        
        &.active{
            &> a{
                &::after {
                    opacity: 1;
                  }
            }
            &>.first_menu{
                display: block;
            }
        }
    }

    @include setMaxRwd{
        display: none;
        &.active{
            display: block;
        }
    }
}

.sideMobilebtn{
    position: absolute;
    top: 5px;
    right: 0;
    .btn{
        padding: 0.5rem 1.5rem !important;
        color: $colorMain !important;
        border-color: $colorMain !important;
        background-color: transparent !important;
        border-radius: 30px;
    }
    @include pad1024{
        display: block !important;
    }
}

.iconRound-more{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #e5e5e5;
    *{
        transition: 0.25s;
    }
    .icon{
        position: relative;
        display: inline-block;
        width: 28px;
        height: 28px;
        overflow: hidden;
        img{
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .txt{
        color:#363535;
        font-size: 16px;
        margin-bottom: 0;
    }
    &:hover{
        background-color: $colorMain;
        .icon{
            img{
                transform: translateY(-28px);
            }
        }
        .txt{
            color:#fff
        }
    }
}

// 
article {
    width: 100%;
    font-size: 100%;
    iframe {
        width: 100%;
        height: 750px;
        @include pad {
            height: 350px;
        }
    }
}


// For 內頁區塊
.normal-content {
    padding:2em 1em;
    overflow: hidden;
    @include setWidthRwd(1100px,1600px){
        padding-left: 0;
        padding-right: 0;
    }
    @include pad1024{
        padding: 1rem 0.95rem !important;
        article{
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
}

// 
.content{
    @extend %setFlex;
    width: 100%;

    &-list{}

    &-info{
        max-width: 100%;
        flex-grow: 1;
    }

    .left{
        @include setMinRwd{
            flex-basis: 405px;
            .block-title-box.titleStyle::before {
                width: calc(40% + 2rem);
                left: -1rem;
            }
            .sidemenu_ul{
                margin-left: 170px;
                padding-right: 5rem;
            }
        }

        @include setWidthRwd(1100px,1600px){
            flex-basis: 360px;
            .sidemenu_ul{
                margin-left: 7rem;
            } 
        }

        @include setWidthRwd(1025px,1100px){
            .sidemenu_ul{
                margin-left: 6rem;
            } 
        }

        @include pad1080{
            flex: 1;
        }
    }

    .right{
        .main-headingStyle {
            max-width: 100%;
        }
        @include setStyleMinRwd(1300px){
            padding-left: 7rem;
            padding-right: 150px;
        }
        @include setMinRwd{
            padding-top: 1.75rem;
            flex: 1;
            article.content{
                padding-top: 2.5rem;
            }
        }
        @include setWidthRwd(1100px,1800px){
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }

    @include pad1080{
        flex-direction: column;
    }
}

// 項目
.listItem{
    width: 100%;
    padding: 1rem 0;
    @include setMinRwd{
        padding: 40px;
    }

    .list{
        background-color: #f0f0f0;
        margin-bottom: 10px;
        @extend %setFlex-center;
        padding: 10px 20px;
    
        .item{
            color:#686767;
            font-weight: 400;
            flex: 0 0 130px;
            border-right: #b5b5b5 1px solid;
        }
    
        .info{
            flex: 1;
            margin-bottom: 0px;
            padding-left: 30px;
        }
    }
}

// 頁碼
.pagination-wrap {
    margin-top: 2rem;
    margin-bottom: 2rem;
    .pagination {
        justify-content: center;
        .page-link {
            @include setSize(35px,35px);
            line-height: 35px;
            text-align: center;
            border-radius: 0;
            border: none;
            padding: 0;
            margin-right: 0.5rem;
            color: #6e6d6d;
            @include fontSize(18px);
            font-family: Arial;
            background-color: transparent;
            border:#424242 1px solid;
            &:hover,
            &.active {
                color: #fff;
                background-color:#424242;
            }
        }
    }
    @include pad1024{
        .pagination-mobile{
            display:flex !important;
            align-items:center;
            width:100%;
            .select_style {
                flex:1;
                margin:0 10px;
                .form-control {
                    border: 1px solid #ced4da !important;
                    background: #fff;
                    text-align:center;
                }
            }
        }
    }
}

// no border
.border{
    &_noTop{
        border-top: none !important;
    }
    &_noBottom{
        border-bottom: none !important;
    }
    &_noLeft{
        border-left: none !important;
    }
    &_noRight{
        border-right: none !important;
    }
}

// no border Radius
.border_noRadius{
    border-radius: 0 !important;
}

// Flex相關
.flex-none{
    flex: none !important;
}