// 表格用
.form-style{
    .form-group{
        @extend %setFlex-center;
        margin-bottom: 1.75rem;
        &>label{
            font-size: 18px;
            @include setMinRwd{
                flex:0 0 150px;
            }
            @include setWidthRwd(1100px,1600px){
                font-size: 16px;
            }
        }

        @include setStyleMinRwd(1101px){
            flex-wrap: nowrap;
        }

        @include setMaxRwd{
            &-confirmPwd{
                .form-control{
                    width: calc(100% - 50px);
                }
            }
        }
    }

    .form{
        &-control{
            background-color: #fff;
            @include border(1px, #9c9b9c, solid);
        }

        &-input{
            @extend %setFlex-center;
            justify-content: space-between;
            margin-top: 2rem;
            color: #777777;
            font-size: 16px;
        }

        &-check,
        &-link{
            flex: 1;
            @include mobile{
                flex: none;
                line-height: 1;
            }
        }
        
        &-check{
            padding-left: 0;
        }
        
        &-link{
            text-align: right;
        }
    }

    .btn_wrap{
        margin-top: 1.5rem;
    }

    .disabled{
        background-color: #e5eef0;
        border-color: #e5eef0
    }
}

.member{
    &-courseName{
        width: 100%;
        @include border(1px,#b5b5b5,solid);
        padding: 10px 20px;
    }

    &-title{
        @extend %setFlex-center;
        justify-content: center;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        margin: 0.5rem 2rem 2rem;
        .icon{
            margin-right: 15px;
        }

        @include setMinRwd{
            font-size: get-vw(36px);
        }

        @include setMaxRwd{
            margin: 1rem;
            .icon{
                img{
                    max-width: 40px;
                }
            }
        }
    }

    // 
    &-normal-content{
        padding-bottom: 0;
        .content{
            .right {
                article.content {
                    padding: 0;
                    margin-bottom: 0;
                }
            }
        }
    }

    // 會員專區框
    &-form-content{
        padding: 2rem 1rem;
        background-color: #fff;
        overflow: hidden;

        .form-group{
            &>label{
                flex-wrap: wrap;
                @include setStyleMinRwd(1600px){
                    flex:0 0 185px;
                }
            }
            .form-check {
                .radio_style{
                    margin-top: 0 !important;
                    margin-left: 0 !important;
                }
            }
            @include setMinRwd{
                flex-wrap: nowrap;
            }
        }

        .form-list{
            @extend %setFlex-center;
            flex-wrap: nowrap;
            .form-check {
                flex: none;
            }
            @include setMinRwd{
                .form-control {
                    flex: 0 0 calc(100% - 150px);
                }
                
                .form-check {
                    width: 150px;
                    flex: none;
                }
            }
        }

        // 針對姓名跟身分證水平置放
        @include setWidthRwd(1100px,1400px){
            .form-col-name{
                max-width: 65%;
            }
            .form-col-id{
                max-width: 35%;
                & > label {
                    flex: 0 0 90px;
                }
            }
        }

        @include setMaxRwd{
            padding: 1rem;
        }

        // 針對出生年月日
        @include setMaxRwd{
            .form-row-birth{
                margin-left: -5px;
                margin-right: -5px;
                .col{
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }

        // 針對表格
        .member_checkout_info {
            background: #eee;

            .memberCart_form_style{
                width: 100%;
                .btn_style{
                    display: inline-block;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-bottom: 15px;
                    font-size: 18px;
                    @include setMinRwd{
                        font-size: get-vw(18px);
                    }
                    @include setWidthRwd(1100px,1600px){
                        font-size: get-vw(22px);
                        padding-left: 10px;
                        padding-right: 10px;
                        padding-bottom: 10px;
                    }
                }
            }
            
            // 標頭 & 內容項目
            .memberCart_form_head,
            .memberCart_form_item tr{
                @include setStyleMinRwd(1101px){
                    border: #eee 10px solid;
                }
            }

            .memberCart_form_head th,
            .memberCart_form_item td{
                padding: 10px;
                text-align: center;
                vertical-align: middle;
            }

            .memberCart_form_head{
                th {
                    font-size: 16px;
                    color: #7b7b7b;
                    font-weight: 300
                }
            }

            .memberCart_form_item{
                tr{
                    @include setStyleMinRwd(1101px){
                        &:nth-child(even){
                            background-color: #f5f5f5;
                        }
                    }
                    @include setStyleMaxRwd(1100px) {
                        background-color: #fff;
                        border: #eee 10px solid;
                        display: block;                      
                    }

                    td{
                        color: #363636;
                        font-size: 18px;
                        background-color: #fff;
                        @include setStyleMaxRwd(1100px) {
                            display: flex;
                            align-items: center;
                            width: 100% !important;
                            text-align: left;
                            border-bottom: 1px solid #ddd;
                            &::before {
                                content: attr(data-title) ":";
                                display: inline-block;
                                min-width: 80px;
                                margin-right: 1rem;
                                margin-top: .15rem;
                                color: #777;
                            }
                        }
                        &:first-child{
                            color:#9b9a9a
                        }
                    }
                }
            }

            // 標頭
            @include setStyleMaxRwd(1100px) {
                .memberCart_form_head{
                    display: none;
                }
            }
        }
    }
}

/* -------------- login --------------*/
.login{
    &-container {
        width: 100%;
    }
    &-grid {
        width: 100%;
        max-width: 650px;
        margin: 10px auto 40px;
        padding: 50px;
        border-radius: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,0.25);

        .form-style{
            .form-group{
                &>label{
                    position: relative;
                    padding-left: 20px;
                    &::before{
                        content: '';
                        display: block;
                        width: 6px;
                        height: 6px;
                        background-color: #010101;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        margin-top: -3px;
                        @include radius(50%);
                    }
                }
            }
        }
    }
}


/* -------------- 內框用 --------------*/
.main{
    &-container{
        display: flex; 
        flex-direction: column;
        width: 100%;
        @include setStyleMinRwd(1101px){
            flex-direction: row;
            flex-wrap: nowrap;
        }
        @include setStyleMaxRwd(1100px){
            padding-top: 1rem;
        }
    }

    &-aside {
        background-color: #eeeeee;
        @include setStyleMinRwd(1101px){
            width: 290px;
            flex-shrink: 0;
            margin-right: 10px;
        }

        @include setWidthRwd(1100px,1600px){
            width: 230px;
        }
    }
    
    &-section {
        flex-grow: 1;
        max-width: 100%;
        padding: 1rem;
        @include setStyleMinRwd(1601px){
            padding: 2.5rem 0 2.5rem 100px;
        }

        @include setMaxRwd{
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-form-title {
        @extend %setFlex-center;
        margin-bottom: 1em;
        .icon{
            margin-right: 15px;
        }
        .title{
            font-size: 24px;
            color: $colorMain;
            letter-spacing: 1px;
            font-weight: 500;
            span {
                margin-top: 5px;
                font-size: 16px;
                color: $colorMain;
                word-spacing: 1px;
                font-weight: normal;
                font-weight: 500;

                display: grid;
                align-items: center;
                grid-template-columns: 1fr max-content 1fr;
                grid-template-rows: 27px 0;
                grid-gap: 20px;
                &::before,
                &::after {
                    content: " ";
                    display: block;
                    border-bottom: 1px solid lighten($colorMain, 15%);
                    border-top: 1px solid lighten($colorMain, 15%);
                    height: 5px;
                }
            }
        }
        
        @include setMaxRwd{
            margin-top: 1rem;
        }
    }
}

.link-wrap{
    display: flex;
    flex-direction: row;
    height: 100%;
    &> li {
        position: relative;
        border-bottom: #686767 1px solid;
        
        &> a {
            position: relative;
            display: block;
            color: $colorBase;
            font-size: 18px;
            line-height: 1.3;
            padding: 1rem;
            &:hover,
            &.active{
                color:$colorMain
            }
            @include setStyleMinRwd(1101px){
                padding: 0.5rem 1rem 1rem 1rem;
            }
        }

        &:hover{
            &:after{
                transform: rotate(180deg);
            }
        }

        @include setStyleMinRwd(1101px){
            &::after{
                content: '';
                display: block;
                background-image: url('../images/icon-arrow-top.png');
                @include setBackSet(center,contain);
                @include setSize(15px,10px);
                position: absolute;
                right: 5px;
                top: 18px;
                transform: rotate(90deg);
                transition: 0.25s;
            }
        }
    }

    @include setStyleMinRwd(1101px){
        flex-direction: column;
        padding: 1.5rem 2rem 3rem;
        &>li{
            & + li {
                margin-top: 0.5rem;
            }
        }
    }
    
    @include setStyleMaxRwd(1100px){
     &>li{
        flex: 1;
        text-align: center;
        &~li{
            border-left: none;
        }
        &>a{
            text-align: center;
            font-size: 1rem;
            &.active{
                border-bottom: 2px solid #757575;
            }
        }
     }
    }
}