.image-slider{
    z-index: 2;

    .content{
        width: 100%;
        margin: auto;
        justify-content: center;
        flex-direction: row;
        @include setMinRwd{
            max-width: get-vw(1400px);
        }
        
        @include pad1080{
            max-width: get-vw(1700px);
        }

        @include mobile{
            max-width:100%;
            flex-direction: column;
            background: #f0f4f3;
            padding: 1rem;
        }
    }

    .swiper-slide {
        position: relative;
        overflow: hidden;
        .index-banner-wrapper {
            @include setMinRwd{
                margin: get-vw(150px) auto get-vw(350px);
            }
            @include setWidthRwd(1100px,1300px){
                margin-bottom: get-vw(400px);
            }
        }
        
        @include setStyleMaxRwd(1100px){
            min-height: get-vw(1024px);
        }

        .img-container {
            @extend %setBack;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding-bottom: 36%;
            @include mobile{
                position: static;
                height: 200px;
            }
        }

        &:nth-child(even){
            .content{
                justify-content: center;
            }
        }

        &:nth-child(odd){
            .content{
                justify-content: flex-start;
            }
        }
    }

    .swiper-button{
        &-next,
        &-prev{
            color:#000;
            @include setMinRwd{
                width: 80px;
                height: 80px;
                color: #fff;
                background: rgba(0,0,0,0.55);
            }
        
            @include mobile{
                top: 25%;
            }
        }
        @include setMinRwd{
            &-next{
                right: 8%;
            }
            &-prev{
                left: 8%
            }
        }
    }

    .text-wrapper {
        overflow: hidden;
        display: inline-flex;
        .text-inner .sub-title,
        .text-inner .titleStyle,
        .text-inner .title{
            transform: translateY(50vw);
            transition: all ease 1s;
        }
    }

    //   /*active states*/
    .swiper-slide-active.active .text-inner .sub-title,
    .swiper-slide.active.active .text-inner .titleStyle,
    .swiper-slide-active.active .text-inner .title {
        transform: translateY(0);
        opacity: 1;
    }
    
    //   /*delays*/
    .swiper-slide.active .text-inner .sub-title {
        transition-delay: 0.05s;
    }

    .swiper-slide.active .text-inner .titleStyle {
        transition-delay: 0.075s;
    }
    
    .swiper-slide.active .text-inner .title {
        transition-delay: 0.1s;
    }
}

.index{
    padding: 1rem;
    @include setMinRwd{
        padding-top: 65px;
        padding-bottom: 65px;
    }

    // Banner
    &-banner{
        @include mobile{
            img{
                height: 200px;
                object-fit: cover;
                object-position: center
            }
        }

        &-title{
            .en {
                color: #000;
                font-family: $MontserratLight;
                @include setMinRwd{
                    font-size: get-vw(36px);
                }
            }

            .titleStyle {
                margin: 1rem 0 1.5rem;
                font-weight: 100;
                &::after{
                    margin-left: 1rem;
                }
                @include setMinRwd{
                    margin: 2rem 0 2.5rem;
                    font-size: get-vw(30px);
                }
            }
            
            .ch{
                color:transparent;
                p{
                    font-weight: 900;
                    color:#000;
                    text-shadow: 3px 3px #fff, -3px 3px #fff, 3px -3px #fff, 3px -3px #fff, 3px 3px 0px #fff;
                    font-family: adobe-fan-heiti-std, sans-serif;
                    letter-spacing: 2px;
                    @include setMinRwd{
                        font-size: get-vw(50px);
                    }
                }
                .space{
                    padding-left: 12px;
                    padding-right: 12px;
                }
            }

            &.white{
                .titleStyle{
                    &::after{
                        margin-left: 0;
                    } 
                }
            }

            @include setMinRwd{
                &.white{
                    .en,
                    .titleStyle,
                    .ch{
                        color:#fff
                    }
                    
                    .titleStyle{
                        margin-top: 3rem;
                        margin-bottom: 3rem;
                        &::after{
                            flex: 0 1 145px;
                            height: 2px;
                            background-color: #fff;
                        }
                    }

                    .ch{
                        p{
                            text-shadow:none;
                            color:#fff;
                            font-size: get-vw(40px);
                        }
                    }
                }
            }

            @include mobile{
                *{
                    color: #374a6f !important;
                }
                .sub-title{
                    font-size: get-vw-mobile(25px);
                }
                .titleStyle {
                    font-size: get-vw-mobile(35px);
                }
                .title{
                    font-size: get-vw-mobile(40px);
                }
            }
        }
    }

    &-course{
        background-color: #eaecec;
        .card__list{
            flex: 0 0 calc((100% / 2) - 16px);
            max-width: calc((100% / 2) - 16px);
        }
    }

    &-ourService{
        @include setBackSet(top,cover);
        background-image: url('../images/index-ourService-bg.jpg');
        &-list{
            @extend %setFlex;
            justify-content: space-between;
            color:#fff;
            .title{
                @include fontSize(36px);
                margin-bottom:20px;
            }

            .list{
                margin-bottom: 10px;
                .item{
                    position: relative;
                    @include fontSize(16px);
                    margin-bottom: 5px;
                    padding-left: 22px;
                    &::before{
                        content: "";
                        display: inline-block;
                        @include setSize(18px,18px);
                        @include setBackSet(center,contain);
                        background-image: url('../images/icon-format.png');
                        position: absolute;
                        left: 0;
                        top: 7px;
                    }
                }
            }

            .block-title-box{
                margin-top: 2rem;
                margin-bottom: 2.5rem;
                &::before {
                    width: 60px;
                }
            }
        }
    }
}