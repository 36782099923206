@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");
@import url("//fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");
@import url("//fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
a, body {
  color: #000;
  font-size: 16px;
  font-family: "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
  -webkit-text-stroke: 0.1px;
  line-height: 1.8;
  font-weight: 400;
}

.venue_info .font-Europa {
  font-family: europa, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.sprite, input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.contact-bg, .image-slider .swiper-slide .img-container {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.dowload-list li, .dowload-item, .contact-form .btn-wrap, .venue_content, .venue_info_priceYear, .venue_info .text, .index-ourService-list, .card-wrap .card__caption, .content {
  display: flex;
  flex-wrap: wrap;
}

.main-form-title, .member-form-content .form-list, .member-title, .form-style .form-input, .form-style .form-group, .venue_item, .venue_title, .news-detail-title, .content-title .wrapper, .listItem .list, .btn-wrap, .radio_style, .modal-center, .main-headingStyle, .nav-fostrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
}
body.fixed {
  overflow: hidden;
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.sh_sans {
  font-family: "source-han-sans-traditional", "微軟正黑體", sans-serif;
}

.sh_serif {
  font-family: "source-han-serif-tc", serif;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

.sprite {
  fill: #007894;
  vertical-align: top;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.font-button .far,
.font-button .fas {
  color: #666;
  transition: 0.08s cubic-bezier(0.37, 0.99, 0.92, 0.96);
}
.font-button:hover .far,
.font-button:hover .fas {
  color: #007894;
}

.zero-size {
  font-size: 0px;
  letter-spacing: 0px;
  word-spacing: 0px;
}

.required {
  color: #007894;
  margin: 0 0 0 8px;
}

.red {
  color: #ff0016;
}

.space {
  display: inline-block;
  width: 7px;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1100px) {
  .hide {
    display: block !important;
  }
}

@keyframes menushow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes visitsLoad {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  30% {
    transform: translate3d(-70%, 0, 0);
  }
  80% {
    transform: translate3d(-20%, 0, 0);
  }
  95% {
    transform: translate3d(-5%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}
@keyframes visitsShow {
  0% {
    opacity: 0;
    transform: translate3d(0, 45px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.table {
  border-top: 1px solid #c8dae8;
  border-right: 1px solid #c8dae8;
  border-left: 1px solid #c8dae8;
}

.input {
  width: 100%;
}

.textarea {
  width: 100%;
  height: 100px;
}

.searchBar {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icon {
  display: inline-block;
  vertical-align: top;
  margin-top: -1px;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.detail_label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail_label.fc-LightSalmon {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}

.nav-header {
  position: sticky;
  top: 0;
  z-index: 1001;
  background: #fff;
}
@media (min-width: 1101px) {
  .nav-header {
    display: flex;
    align-items: center;
  }
  .nav-header .nav-fostrap {
    flex: 1;
  }
  .nav-header .nav-right {
    flex-basis: 630px;
    padding-right: 10px;
  }
  .nav-header.active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }
}
@media (min-width: 1651px) and (max-width: 1890px) {
  .nav-header .nav-right {
    flex-basis: 500px;
  }
}
@media (min-width: 1100px) and (max-width: 1650px) {
  .nav-header .nav-fostrap {
    display: block;
  }
  .nav-header .nav-fostrap ul {
    display: flex;
    justify-content: space-between;
  }
  .nav-header .nav-right {
    position: absolute;
    right: 10px;
    width: 630px;
    top: 1.25rem;
  }
}
@media (max-width: 1080px) {
  .nav-header {
    padding: 1rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  }
  .nav-header .nav-right {
    display: none;
    flex-direction: column;
  }
  .nav-header .nav-right.visible {
    display: flex;
    position: fixed;
    background: #fff;
    width: 100%;
    padding: 1rem 0.25rem;
    left: 0;
    top: 5rem;
    z-index: 2;
  }
  .nav-header .nav-right .input-link {
    margin-top: 10px;
    justify-content: space-around;
  }
  .nav-header .nav-right .input-group .icon {
    display: none;
  }
}

.nav-fostrap {
  justify-content: space-between;
  background: #fff;
}
.nav-fostrap ul {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
  z-index: 2;
}
@media (min-width: 1101px) {
  .nav-fostrap ul {
    padding-left: 4rem;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .nav-fostrap ul {
    padding-left: 0;
  }
}
.nav-fostrap ul > li {
  list-style-type: none;
  margin: 0;
  display: inline-block;
  position: relative;
}
.nav-fostrap ul > li > a {
  position: relative;
  z-index: 1;
  font-size: 20.5px;
  font-size: 1.28125rem;
  color: #000;
  display: inline-block;
  outline: 0;
  background: none;
  border: none;
  padding: 0.1rem 0.6em;
  transition: color 0.2s;
  transform: translateZ(0);
  overflow: hidden;
}
@media (min-width: 1101px) {
  .nav-fostrap ul > li > a:before, .nav-fostrap ul > li > a:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    will-change: transform;
    transition: transform 0.3s ease;
    pointer-events: none;
    transform: translateZ(0);
    border-radius: 20px;
  }
  .nav-fostrap ul > li > a:before {
    border-color: #007894 transparent;
    transform: skew(-86deg, 0);
  }
  .nav-fostrap ul > li > a:after {
    border-color: transparent #007894;
    transform: skew(0, 40deg);
    transition: transform 0.4s;
  }
}
@media (min-width: 1100px) and (max-width: 1650px) {
  .nav-fostrap ul > li > a {
    font-size: 18.5px;
    font-size: 1.15625rem;
    padding-left: 2.6rem;
    padding-right: 2.6rem;
  }
  .nav-fostrap ul > li > a + ul li a {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 1100px) and (max-width: 1250px) {
  .nav-fostrap ul > li > a {
    padding-left: 2.15rem;
    padding-right: 2.15rem;
  }
}
.nav-fostrap ul > li:hover a, .nav-fostrap ul > li.active a {
  color: #007894;
}
.nav-fostrap ul > li:hover a:before, .nav-fostrap ul > li:hover a:after, .nav-fostrap ul > li.active a:before, .nav-fostrap ul > li.active a:after {
  transform: skew(0, 0);
}
.nav-fostrap ul > li:hover ul.dropdown {
  opacity: 1;
  visibility: visible;
}
.nav-fostrap-link {
  display: flex;
  align-items: center;
  flex: auto;
}
@media (max-width: 1080px) {
  .nav-fostrap-link {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
  .nav-fostrap-link ul {
    width: 100%;
  }
}
@media (min-width: 1101px) {
  .nav-fostrap {
    padding: 0.5rem 1rem 0.75rem;
  }
  .nav-fostrap li {
    padding: 0.5rem 0.25rem 0;
  }
}
@media (min-width: 1005px) and (max-width: 1699px) {
  .nav-fostrap {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 1005px) and (max-width: 1200px) {
  .nav-fostrap li a {
    font-size: 16px;
  }
}

.dropdown {
  width: 100%;
  min-width: 100px;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  padding-top: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  text-align: center;
  background-color: #fff;
  border: #898989 1px solid;
  padding: 3px !important;
  display: block !important;
}
.dropdown::before, .dropdown::after {
  content: "";
  width: 0;
  height: 0;
  border-bottom: #898989 10px solid;
  border-top: transparent 10px solid;
  border-left: transparent 10px solid;
  border-right: transparent 10px solid;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.dropdown::before {
  border-bottom-color: #fff;
  top: -19px;
  z-index: 2;
}
.dropdown.active {
  display: block !important;
  opacity: 1;
  visibility: visible;
}
.dropdown li {
  padding: 0.5rem 0.25rem 0 !important;
}
.dropdown li::before {
  display: none;
}
.dropdown li,
.dropdown li a {
  display: block;
  width: 100%;
}
.dropdown li a {
  padding: 0.65rem 0.3rem;
  font-weight: 400;
  color: #000;
  line-height: 1.5;
  font-size: 17px !important;
}
.dropdown li a::after {
  display: none;
}
.dropdown li a::before, .dropdown li a:after {
  display: none !important;
}
.dropdown li:hover {
  background-color: #E5E5E5;
}
@media (max-width: 1100px) {
  .dropdown {
    position: static;
    display: none !important;
    border: none;
    margin-left: 0;
    margin-top: 2px;
    transform: translateX(0);
  }
  .dropdown::before, .dropdown::after {
    display: none;
  }
  .dropdown.active {
    display: block !important;
    opacity: 1;
    visibility: visible;
  }
  .dropdown li {
    padding: 0;
  }
  .dropdown li a {
    border-bottom: none;
    text-align: left;
  }
}

.nav-fostrap-menuBg {
  position: absolute;
  left: 0;
  top: 5rem;
  width: 100%;
  height: 65px;
  background: rgba(212, 232, 206, 0.37);
  z-index: 1;
  display: none;
}
.nav-fostrap-menuBg.active {
  display: block;
}

.arrow-down {
  position: absolute;
  right: 1rem;
}

.title-mobile {
  display: none;
}

@media (min-width: 1025px) {
  .container {
    width: 96%;
  }
  .nav-bg-fostrap,
.arrow-down {
    display: none;
  }
}
@media (min-width: 1601px) and (max-width: 1750px) {
  .nav-logo img {
    max-width: 15vw;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .nav-logo {
    display: block;
    width: 30vw;
  }
}
@media (max-width: 1080px) {
  .nav-fostrap {
    background: #fff;
    width: 45vw;
    height: 100%;
    display: block;
    position: fixed;
    left: -100%;
    top: 0px;
    transition: left 0.25s ease;
    z-index: 12;
  }
  .nav-fostrap li a::after {
    display: none !important;
  }
  .title-mobile {
    display: block;
    max-width: 50vw;
  }
  .nav-logo {
    display: block;
    margin: auto;
  }
  .mobile-closeBtn {
    width: 40px;
    height: 40px;
    background-color: #000;
    text-align: center;
    color: #fff;
    position: absolute;
    right: -40px;
    top: -74px;
    z-index: 6;
    font-size: 25px;
    font-size: 1.5625rem;
  }
  .mobile-closeBtn a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .nav-fostrap.visible {
    left: 0px;
    transition: left 0.25s ease;
  }
  .nav-bg-fostrap {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 65px;
    margin: 0;
    z-index: 1;
  }
  .navbar-fostrapClick {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    padding: 12px;
  }
  .navbar-fostrapClick span {
    height: 2px;
    background: #007894;
    margin: 3px 5px;
    display: block;
    width: 20px;
  }
  .navbar-fostrapClick span:nth-child(2), .navbar-fostrapClick span:nth-child(3) {
    width: 20px;
  }
  .navbar-fostrapClick .txt {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1;
  }
  .navbar-fostrapClick.member {
    right: 4rem;
    display: flex;
    align-items: center;
    padding-top: 6px;
  }
  .navbar-fostrapClick.member .icon {
    max-width: 20px;
    margin-top: -6px;
  }
  .nav-fostrap ul {
    padding-top: 5px;
  }
  .nav-fostrap li {
    display: block !important;
    border-bottom: #eee 1px solid;
  }
  .nav-fostrap li a {
    display: block !important;
    font-size: 16px;
    font-size: 1rem;
    padding: 6px 12px !important;
  }
  .nav-fostrap li:first-child:hover a {
    border-radius: 0;
  }
  .nav-fostrap li:hover a, .nav-fostrap li.active a {
    background: #007894;
    color: #fff !important;
  }
  .nav-fostrap li a.active {
    background: #00637b;
    color: #fff !important;
  }
  .nav-fostrap li a.active .arrow-down {
    transform: rotate(180deg);
  }
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.nav-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: none;
  opacity: 0;
}
.nav-overlay.show {
  display: block;
  opacity: 1;
}

.nav-right {
  display: inline-flex;
}
.nav-right .input-link {
  flex: none;
}
.nav-right .form-control,
.nav-right .input-group-btn {
  border-radius: 30px !important;
}
.nav-right .input-group-btn {
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 0 !important;
}
.nav-right .input-group-btn .btn {
  color: #fff;
  background-color: #555555;
  border-radius: 30px !important;
  padding: 0.4rem 1rem;
}
.nav-right .form-control {
  background-color: #fff;
  height: 40px;
}
.nav-right .form-control + .form-control {
  margin-left: 10px;
}

.input-group, .input-link {
  padding: 7.5px 10px;
  border-radius: 40px;
  overflow: hidden;
}
.input-group {
  align-items: center;
  background-color: #dcdcdc;
}
.input-group .icon {
  margin-left: 5px;
  margin-right: 5px;
}
.input-link {
  display: flex;
  align-items: center;
  border: #c9c9c9 1px solid;
  color: #777777;
  font-size: 14px;
  margin-left: 2px;
}
.input-link a {
  margin-left: 5px;
  margin-right: 5px;
}

footer {
  background: #000;
  color: #fff;
  padding: 25px 0 35px;
}
footer .wrapper-1500 {
  display: flex;
  align-items: center;
}
@media (max-width: 1100px) {
  footer .wrapper-1500 {
    flex-direction: column;
  }
}
@media (max-width: 1600px) {
  footer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  footer .wrapper {
    flex-wrap: wrap;
  }
  footer .divider {
    display: none;
  }
}
@media (max-width: 1080px) {
  footer {
    padding: 1rem 0.75rem;
  }
}
@media (max-width: 640px) {
  footer .wrapper {
    flex-direction: column;
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}

.footer-logo {
  display: flex;
  align-items: center;
}
.footer-logo img {
  display: block;
  max-width: 100%;
}
.footer-logo .txt-big {
  font-size: 24px;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0px !important;
  margin-left: 1rem;
}

.footer {
  display: flex;
}
.footer-info .list {
  display: flex;
  align-items: center;
}
.footer-info .list .img {
  width: 50px;
  flex: none;
  margin-right: 10px;
}
@media (max-width: 1080px) {
  .footer-info .list .img {
    margin-right: 5px;
  }
}
.footer-info .list small {
  display: block;
  color: #383838;
  margin-bottom: 3px;
  margin-top: 7px;
}
.footer-info .list .time {
  padding-right: 10px;
}
.footer-info .list .txt {
  letter-spacing: 1px;
  padding: 8px 15px 0 5px;
  line-height: 1.5;
  font-size: 17px;
}
.footer-info .list .txt a {
  text-decoration: underline;
  margin-left: 5px;
}
.footer-info .list .txt p {
  align-items: center;
  margin-bottom: 5px;
  margin-right: 10px;
}
@media (min-width: 1101px) {
  .footer-info .list .txt,
.footer-info .list .txt p {
    display: flex;
    flex-wrap: wrap;
  }
  .footer-info .list .txt p {
    flex: none;
  }
  .footer-info .list .txt p > span {
    flex: none;
    padding-right: 5px;
  }
}
@media (min-width: 1400px) {
  .footer-info .list .txt p {
    margin-bottom: 0px;
    margin-right: 20px;
  }
  .footer-info .list .txt p:last-of-type {
    margin-right: 10px;
  }
}
@media (max-width: 1080px) {
  .footer-info {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .footer-info .list {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .footer-info .list .txt {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-top: 0;
  }
  .footer-info .list .txt p {
    line-height: 1.5;
    margin-left: 0 !important;
    margin-bottom: 10px;
  }
  .footer-info .list .txt-big {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.footer-social p {
  margin-bottom: 0;
}
@media (min-width: 1101px) {
  .footer-social {
    margin-top: 5px;
    margin-left: 20px;
    text-align: center;
  }
}
@media (max-width: 1024px) {
  .footer-social {
    margin-top: 10px;
  }
  .footer-social p {
    margin-left: 5px;
  }
}
.footer-social .social-icon {
  margin-bottom: 10px;
}
.footer.left, .footer.center, .footer.right {
  flex-grow: 1;
  max-width: 100%;
  display: inline-flex;
  align-items: flex-start;
}
.footer.left {
  align-items: center;
}
.footer.left .txt {
  padding-top: 0;
}
.footer.left .footer-info {
  padding-top: 1.5rem;
}
@media (min-width: 1101px) {
  .footer.left .footer-info {
    padding-left: 45px;
  }
}
@media (max-width: 810px) {
  .footer.left {
    flex-direction: column;
  }
}
@media (min-width: 1101px) {
  .footer.right {
    flex: none;
    padding-left: 60px;
  }
}
.footer.center .txt {
  line-height: 2.2;
}
.footer.center .txt p ~ p small {
  color: #10100f;
  font-size: 16px;
  font-size: 1rem;
}
@media (max-width: 1080px) {
  .footer.center .txt {
    line-height: 1.5;
  }
  .footer.center .txt p ~ p small span {
    display: block;
    margin-bottom: 5px;
  }
}
@media (min-width: 1025px) and (max-width: 1100px) {
  .footer.center {
    margin-left: 15px;
    margin-right: 5px;
  }
}
@media (min-width: 1101px) {
  .footer.left .list .txt p br {
    display: none;
  }
}

.iware {
  color: #fff;
  font-size: 13px;
  font-size: 73%;
  font-weight: 100;
  padding: 5px 8px 6px;
}
.iware a {
  color: #fff;
}
.iware a:hover {
  text-decoration: underline;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
}
.float-link .link-btn a .circle {
  background: #007894;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  margin-bottom: 5px;
}
.float-link .link-btn a .circle::before {
  content: "";
  display: block;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  left: 5px;
  top: 5px;
}
.float-link .link-btn a .circle::after {
  content: "\f077";
  font-family: "FontAwesome";
  display: block;
  font-size: 25px;
  font-size: 1.5625rem;
  color: #fff;
}
.float-link .link-btn a .txt {
  display: block;
  color: #007894;
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 1.2;
  text-align: center;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
.float-link.active {
  right: 15px;
}
@media (max-width: 1600px) {
  .float-link .link-btn a span {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1080px) {
  .float-link {
    bottom: 10px;
  }
  .float-link .link-btn {
    flex: 1;
  }
  .float-link .link-btn br {
    display: none;
  }
  .float-link .link-btn a {
    width: 100%;
    height: 55px;
    border-radius: 0;
    margin: 0;
  }
  .float-link .link-btn a::before {
    display: none;
  }
  .float-link .link-btn a,
.float-link .link-btn a span {
    flex-direction: column !important;
  }
}

.cookies {
  display: none;
  width: 100%;
  position: fixed;
  background: white;
  border-top: 1px solid #aeadad;
  color: #333;
  font-size: 15px;
  font-size: 0.9375rem;
  font-size: 0.9375rem;
  text-align: center;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: 0.5rem 1rem;
}
.cookies > p {
  font-weight: 400;
  line-height: 2;
}
.cookies > p a {
  text-decoration: underline !important;
  color: blue;
  font-weight: 500;
}
.cookies > p .btn {
  color: #fff;
  display: inline;
  font-size: 13px;
  font-size: 0.8125rem;
  text-decoration: none !important;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.cookies.display {
  display: block;
  animation: cookies 1s 1;
}
@media (max-width: 810px) {
  .cookies {
    width: calc(100% - 1em);
    margin: 0.5em;
    border-top: none;
    font-size: 14px;
    font-size: 0.875rem;
  }
  .cookies.close-cookies {
    margin-top: -1em !important;
    margin-bottom: -1em !important;
  }
}

.close-cookies {
  transition: all 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}
.footer-link-logo {
  background-color: #e5e5e5;
  padding-top: 60px;
  padding-bottom: 60px;
}
.footer-link-logo .swiper {
  margin-left: 3rem;
  margin-right: 3rem;
}
.footer-link-logo .swiper-wrapper {
  /* 通过改变animation-timing-function 制作弹性切换效果 */
  transition: 1.5s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
}
.footer-link-logo .swiper-button-next,
.footer-link-logo .swiper-button-prev {
  color: #aaa;
}
.footer-link-logo .swiper-button-next::after,
.footer-link-logo .swiper-button-prev::after {
  font-size: 2rem;
}

.main-banner img {
  width: 100%;
}
@media (max-width: 640px) {
  .main-banner img {
    height: 125px;
    object-fit: cover;
    object-position: left;
  }
}

.main-headingStyle {
  justify-content: space-between;
  margin-bottom: 1rem;
}
@media (min-width: 1600px) {
  .main-headingStyle {
    max-width: 91%;
  }
}
@media (max-width: 1100px) {
  .main-headingStyle {
    margin-bottom: 1rem;
  }
}

.breadcrum-wrap .breadcrumb {
  font-size: 16px;
  font-size: 1rem;
  color: #404040;
  background-color: transparent;
  margin-bottom: 0.25rem;
  padding: 0.5rem 0;
}
.breadcrum-wrap .breadcrumb .icon-home {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item {
  padding-left: 0;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 19px;
  background: url("../images/icon-arrow03.png") no-repeat center;
  padding-top: 1.75rem;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item.active {
  color: #323232;
}
@media (min-width: 1101px) {
  .breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 2rem;
  }
}

.text-main {
  color: #42818f !important;
}

.bg-second {
  background-color: #eeeeee !important;
}

.text-unnderLine {
  text-decoration: underline;
}

.headingStyle {
  margin-bottom: 1.5rem;
}
.headingStyle .title {
  font-size: 2.5rem;
}

.block-title-box {
  font-size: 45px;
  font-size: 2.8125rem;
  line-height: 1.25;
  margin-bottom: 1.25rem;
  z-index: 10;
}
.block-title-box p {
  margin-bottom: 0;
}
.block-title-box .small {
  margin-bottom: 5px;
  padding-left: 5px;
  font-size: 20px;
}
@media (min-width: 1101px) {
  .block-title-box .small {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.block-title-box .txt {
  font-size: 25px;
}
@media (min-width: 1101px) {
  .block-title-box .txt {
    font-size: 1.8947368421vw;
  }
}
.block-title-box.titleStyle {
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5.5rem;
}
.block-title-box.titleStyle::before {
  width: calc(40% + 2rem);
  position: absolute;
  top: 8px;
  left: -1rem;
}
@media (max-width: 1100px) {
  .block-title-box.titleStyle::before {
    width: 5rem;
    top: 12px;
  }
}
@media (min-width: 1101px) {
  .block-title-box.titleStyle {
    padding-left: 8.6842105263vw;
  }
}
.block-title-box.titleStyle.white {
  color: #fff;
}
.block-title-box.titleStyle.white::before {
  background-color: #fff;
}
@media (max-width: 640px) {
  .block-title-box {
    margin-bottom: 0.25rem;
  }
}

.block-titleImg-box {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.block-titleImg-box .block-title {
  margin: 1rem 0rem 1rem 0.75rem;
  line-height: 1.3;
}
.block-titleImg-box .block-title p {
  margin-bottom: 5px;
}
.block-titleImg-box .block-title .txt-small {
  color: #888787;
}
.block-titleImg-box .block-title .txt-big {
  font-size: 24px;
  font-size: 1.5rem;
  color: #010101;
}

.titleStyle {
  position: relative;
  display: flex;
  align-items: center;
}
.titleStyle::before, .titleStyle::after {
  content: "";
  display: block;
  flex: 1;
  height: 2px;
  background-color: #030303;
}
.titleStyle::before {
  margin-right: 5px;
}
.titleStyle::after {
  margin-left: 5px;
}
.titleStyle-left::after {
  display: none;
}
.titleStyle-right::before {
  display: none;
}

.fontThrough {
  text-decoration: line-through;
}

@media (min-width: 1101px) {
  .block-pl85 {
    padding-left: 85px;
  }
  .block-pl45 {
    padding-left: 45px;
  }
  .block-pl35 {
    padding-left: 35px;
  }
}
.bg-intro {
  background-color: #eeeae9;
}

.style__underLine {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: #fff;
}
.style__underLine__small {
  border-top: none;
  border-bottom-width: 2px;
  border-bottom-style: dotted;
}

.tag-item {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  padding: 15px;
  text-align: center;
  color: white;
  background: #007894;
  line-height: 1.2;
}
.tag-item.main {
  border: #0097d8 1px solid;
  color: #0097d8 !important;
  font-size: 17px !important;
  padding: 8px 12px;
}
.tag-item.main_line {
  border-radius: 20px;
  border: #007894 1px solid;
  color: #007894 !important;
  background-color: transparent;
  font-size: 17px !important;
  padding: 8px 12px;
}
.tag-item.main_line.big {
  padding: 0.75rem 2rem;
  border-radius: 35px;
}
.tag-item.main_line_dark {
  border-radius: 20px;
  border: #000 1px solid;
  color: #000 !important;
  background-color: transparent;
  font-size: 17px !important;
  padding: 10px 26px;
}
.tag-item.main_line_dark:hover {
  background-color: #007894;
  border-color: #007894;
  color: #fff !important;
}
.tag-item.main_style {
  background-color: #739ab7;
  color: #fff;
  font-size: 18px !important;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 35px;
}
.tag-item.main_style:hover {
  border-color: #007894;
}
.tag-item.main_style_line {
  border: #739ab7 1px solid;
  color: #739ab7 !important;
  background-color: #fff;
  font-size: 18px !important;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 35px;
}
.tag-item.main_style_line:hover {
  background-color: #007894;
  border-color: #007894;
  color: #fff !important;
}
.tag-item.sub {
  background-color: #f0f0f0;
  color: #686767;
  padding-left: 2rem;
  padding-right: 2rem;
}
.tag-item.sub_line {
  background-color: transparent;
  border-radius: 35px;
  border: #686767 1px solid;
  color: #686767;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.tag-item.small {
  padding: 8px 10px;
}

.form-control {
  border: none;
  height: 50px;
}
.form-control, .form-control:focus {
  background-color: #eeeeee;
}

.form-control,
.btn {
  border-radius: 0;
}

.btn:hover {
  background-color: #007894;
  color: #fff;
}

.modal {
  text-align: center;
  padding-right: 0 !important;
}
.modal .modal-content {
  border-color: transparent;
  border-radius: 20px;
}

.modal-open {
  padding-right: 0 !important;
}

.modal-orderCancel .modal-dialog {
  max-width: 520px;
}
.modal-orderCancel .modal-content {
  background: #eee;
}
.modal-orderCancel .modal-header {
  border-bottom: none;
}
.modal-orderCancel .modal-body {
  border-radius: 20px;
  background: #fff;
  margin: 0.5rem 1rem 1.5rem;
  padding: 1.5rem 0;
}
.modal-orderCancel .close {
  font-size: 2rem;
}

.modal-center {
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  color: #020202;
}
.modal-center .icon {
  margin-bottom: 0.5rem;
}

@media (min-width: 500px) {
  .modal-dialog {
    width: 100%;
    max-width: 85rem;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .modal-dialog {
    max-width: 70rem;
  }
}
.modal-autoheight .modal-body {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px !important;
  max-height: 100vh !important;
}
@media (min-width: 500px) {
  .modal-autoheight .modal-body {
    max-height: calc(100vh - 50px) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight .modal-body {
    height: calc(90vh - 1.5rem) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight {
    transform: translateY(5%);
  }
}

@media (min-width: 1101px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
.checkbox_style > input[type=checkbox] {
  width: 100%;
  height: 30px;
  position: absolute;
  top: 0px;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.checkbox_style > input + label {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 2px;
  margin-bottom: 5px;
  margin-right: 2px;
  margin-left: 0;
  padding-left: 1.75rem;
  cursor: pointer;
  border: none;
}
.checkbox_style > input + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  border: #000 1px solid;
  flex: none;
}
.checkbox_style > input + label::after {
  content: "\f00c";
  font-family: FontAwesome;
  position: absolute;
  left: 2px;
  font-size: 1rem;
  opacity: 0;
}
.checkbox_style > input:checked + label::after {
  opacity: 1;
}
.checkbox_style p {
  margin-top: 10px;
}
.checkbox_style_gray > input + label::before {
  width: 30px;
  height: 30px;
  border: #b5b5b5 1px solid;
  background-color: #b5b5b5;
  border-radius: 4px;
}
.checkbox_style_gray > input + label::after {
  left: 3px;
  font-size: 1.5rem;
  color: #fff;
}

.radio_style {
  margin-top: 10px !important;
}
.radio_style input[type=radio] {
  display: none;
}
.radio_style input + label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 0;
}
.radio_style input + label::before, .radio_style input + label::after {
  content: "";
  display: inline-block;
  border-radius: 50%;
}
.radio_style input + label::before {
  flex: none;
  width: 20px;
  height: 20px;
  border: #cecece 1px solid;
  background: #fff;
  vertical-align: middle;
  margin-right: 6px;
}
.radio_style input + label::after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translate(0%, -50%);
}
.radio_style input:checked + label::after {
  background-color: #2d456c;
}
@media (min-width: 1101px) {
  .radio_style ~ .radio_style {
    margin-left: 1rem;
  }
}

.btn-wrap {
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (max-width: 1080px) {
  .btn-wrap {
    justify-content: center !important;
    margin-top: 1rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}

.btn {
  padding-top: 0.65em;
  padding-bottom: 0.65em;
  font-size: 18px !important;
}
.btn-main {
  background-color: #007894;
  border-color: #007894;
  border-radius: 20px;
}

.btn_style {
  border-radius: 5px;
  background: #007894;
  display: block;
  color: #fff !important;
  font-size: 15px;
  line-height: 15px;
  padding: 12px 25px;
  transition: background 0.4s;
}
.btn_style:hover {
  background: #202020;
}
.btn_style.active {
  background: #676767;
}
.btn_style.active:hover {
  background: #000;
}
.btn_style.prev i {
  margin-right: 10px;
}
.btn_style.next {
  background: #676767;
}
.btn_style.next:hover {
  background: #202020;
}
.btn_style.next i {
  margin-left: 10px;
}

.sidemenu_ul > li {
  list-style: none;
  width: 100%;
  margin-bottom: 10px;
}
.sidemenu_ul > li > a {
  color: #676666;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.5;
  display: block;
  position: relative;
  width: 100%;
  padding: 10px;
  text-align: center;
}
.sidemenu_ul > li > a::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  border: #3e3e3e 1px solid;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.25s;
  opacity: 0;
}
.sidemenu_ul > li > a:hover::after {
  opacity: 1;
}
.sidemenu_ul > li > .first_menu {
  display: none;
}
.sidemenu_ul > li > .first_menu > li {
  list-style: none;
  padding: 15px 10px;
  margin-bottom: 2px;
}
.sidemenu_ul > li > .first_menu > li > a {
  font-size: 18px;
  font-size: 1.125rem;
  color: #7f7f7f;
  display: block;
  padding-left: 20px;
  position: relative;
  line-height: 1;
}
.sidemenu_ul > li.active > a::after {
  opacity: 1;
}
.sidemenu_ul > li.active > .first_menu {
  display: block;
}
@media (max-width: 1100px) {
  .sidemenu_ul {
    display: none;
  }
  .sidemenu_ul.active {
    display: block;
  }
}

.sideMobilebtn {
  position: absolute;
  top: 5px;
  right: 0;
}
.sideMobilebtn .btn {
  padding: 0.5rem 1.5rem !important;
  color: #007894 !important;
  border-color: #007894 !important;
  background-color: transparent !important;
  border-radius: 30px;
}
@media (max-width: 1024px) {
  .sideMobilebtn {
    display: block !important;
  }
}

.iconRound-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #e5e5e5;
}
.iconRound-more * {
  transition: 0.25s;
}
.iconRound-more .icon {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
  overflow: hidden;
}
.iconRound-more .icon img {
  position: absolute;
  top: 0;
  left: 0;
}
.iconRound-more .txt {
  color: #363535;
  font-size: 16px;
  margin-bottom: 0;
}
.iconRound-more:hover {
  background-color: #007894;
}
.iconRound-more:hover .icon img {
  transform: translateY(-28px);
}
.iconRound-more:hover .txt {
  color: #fff;
}

article {
  width: 100%;
  font-size: 100%;
}
article iframe {
  width: 100%;
  height: 750px;
}
@media (max-width: 810px) {
  article iframe {
    height: 350px;
  }
}

.normal-content {
  padding: 2em 1em;
  overflow: hidden;
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .normal-content {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 1024px) {
  .normal-content {
    padding: 1rem 0.95rem !important;
  }
  .normal-content article {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.content {
  width: 100%;
}
.content-info {
  max-width: 100%;
  flex-grow: 1;
}
@media (min-width: 1101px) {
  .content .left {
    flex-basis: 405px;
  }
  .content .left .block-title-box.titleStyle::before {
    width: calc(40% + 2rem);
    left: -1rem;
  }
  .content .left .sidemenu_ul {
    margin-left: 170px;
    padding-right: 5rem;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .content .left {
    flex-basis: 360px;
  }
  .content .left .sidemenu_ul {
    margin-left: 7rem;
  }
}
@media (min-width: 1025px) and (max-width: 1100px) {
  .content .left .sidemenu_ul {
    margin-left: 6rem;
  }
}
@media (max-width: 1080px) {
  .content .left {
    flex: 1;
  }
}
.content .right .main-headingStyle {
  max-width: 100%;
}
@media (min-width: 1300px) {
  .content .right {
    padding-left: 7rem;
    padding-right: 150px;
  }
}
@media (min-width: 1101px) {
  .content .right {
    padding-top: 1.75rem;
    flex: 1;
  }
  .content .right article.content {
    padding-top: 2.5rem;
  }
}
@media (min-width: 1100px) and (max-width: 1800px) {
  .content .right {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (max-width: 1080px) {
  .content {
    flex-direction: column;
  }
}

.listItem {
  width: 100%;
  padding: 1rem 0;
}
@media (min-width: 1101px) {
  .listItem {
    padding: 40px;
  }
}
.listItem .list {
  background-color: #f0f0f0;
  margin-bottom: 10px;
  padding: 10px 20px;
}
.listItem .list .item {
  color: #686767;
  font-weight: 400;
  flex: 0 0 130px;
  border-right: #b5b5b5 1px solid;
}
.listItem .list .info {
  flex: 1;
  margin-bottom: 0px;
  padding-left: 30px;
}

.pagination-wrap {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.pagination-wrap .pagination {
  justify-content: center;
}
.pagination-wrap .pagination .page-link {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 0;
  border: none;
  padding: 0;
  margin-right: 0.5rem;
  color: #6e6d6d;
  font-size: 18px;
  font-size: 1.125rem;
  font-family: Arial;
  background-color: transparent;
  border: #424242 1px solid;
}
.pagination-wrap .pagination .page-link:hover, .pagination-wrap .pagination .page-link.active {
  color: #fff;
  background-color: #424242;
}
@media (max-width: 1024px) {
  .pagination-wrap .pagination-mobile {
    display: flex !important;
    align-items: center;
    width: 100%;
  }
  .pagination-wrap .pagination-mobile .select_style {
    flex: 1;
    margin: 0 10px;
  }
  .pagination-wrap .pagination-mobile .select_style .form-control {
    border: 1px solid #ced4da !important;
    background: #fff;
    text-align: center;
  }
}

.border_noTop {
  border-top: none !important;
}
.border_noBottom {
  border-bottom: none !important;
}
.border_noLeft {
  border-left: none !important;
}
.border_noRight {
  border-right: none !important;
}

.border_noRadius {
  border-radius: 0 !important;
}

.flex-none {
  flex: none !important;
}

.card-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.card-wrap .card {
  width: auto;
  height: auto;
  margin: 2px 8px 10px;
  box-shadow: none;
  border-radius: 0 !important;
}
.card-wrap .card p {
  position: relative;
  z-index: 3;
  margin: 0;
}
.card-wrap .card__body {
  padding: 0.5rem 0;
  line-height: 1.5;
}
.card-wrap .card-image-top {
  margin-bottom: 0.5rem;
}
.card-wrap .card__image {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.card-wrap .card__image img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 1100px) {
  .card-wrap .card__image {
    margin-top: 10px;
  }
}
.card-wrap .card__info {
  width: 100%;
}
.card-wrap .card__info__image {
  margin-left: 10px;
}
.card-wrap .card__title {
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}
@media (min-width: 1101px) {
  .card-wrap .card__title.big {
    font-size: 1.3157894737vw;
    margin-top: 1.25rem;
    margin-bottom: 2rem;
  }
}
.card-wrap .card__caption {
  justify-content: space-between;
}
.card-wrap .card__time {
  font-size: 16px;
  font-size: 1rem;
  color: #666666;
  font-family: "Roboto";
}
.card-wrap .card__link {
  position: static !important;
  display: block;
  width: 40px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/arrowAll_left.png");
}
.card-wrap .card__link .txtHide {
  color: #007894;
  position: absolute;
  right: 15%;
  top: -20%;
  opacity: 0;
  visibility: hidden;
  transition: 0.25s;
}
.card-wrap .card__des {
  margin-top: 5px;
  margin-bottom: 20px;
}
.card-wrap .card__des .icon {
  position: absolute;
  left: 0;
}
.card-wrap .card__des .icon + .time, .card-wrap .card__des .icon + .txt {
  display: inline-block;
  padding-left: 2rem;
}
.card-wrap .card__href {
  padding-bottom: 8px;
  border-bottom: #000 1px solid;
}
.card-wrap .card__href .icon {
  vertical-align: top;
}
.card-wrap .card__list {
  align-items: flex-start;
  flex: 0 0 calc(25% - 16px);
  max-width: calc(25% - 16px);
  padding: 1rem;
}
.card-wrap .card__list.horizontal .card__info {
  flex: 1;
}
@media (min-width: 1101px) {
  .card-wrap .card__list {
    padding: 2rem;
  }
  .card-wrap .card__list.horizontal {
    flex-direction: row;
    align-items: center;
  }
  .card-wrap .card__list.horizontal .card__info + .card__image {
    margin-left: 10px;
  }
  .card-wrap .card__list.main {
    flex: 0 0 calc(33% - 16px);
    max-width: calc(33% - 16px);
  }
}
.card-wrap .card__btn {
  text-align: center;
}
.card-wrap .card:hover {
  border-color: #007894;
}
@media (min-width: 1101px) {
  .card-wrap .card:hover .card__link {
    background-image: url("../images/arrowAll_left_active.png");
  }
  .card-wrap .card:hover .card__link .txtHide {
    opacity: 1;
    visibility: visible;
  }
  .card-wrap .card:hover .card__href {
    color: #007894;
  }
}
.card-wrap.main {
  width: 100%;
}
@media (min-width: 1101px) {
  .card-wrap.main {
    justify-content: flex-start;
  }
}
@media (max-width: 810px) {
  .card-wrap {
    justify-content: flex-start;
    margin: 1rem 0;
  }
  .card-wrap .card {
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 0.5rem !important;
    max-width: calc(50% - 4px) !important;
    flex: 0 0 calc(50% - 4px) !important;
  }
}
@media (max-width: 640px) {
  .card-wrap .card__title {
    font-size: 3.90625vw;
  }
  .card-wrap .card__caption {
    font-size: 3.125vw;
  }
}

.txtMin {
  min-height: 65px;
  overflow: hidden;
}

.price {
  color: #42818f;
  font-size: 19px;
  font-size: 1.1875rem;
}

.card__note {
  text-align: center;
  color: #007894;
  background-color: #aed5d7;
  border-radius: 1.0526315789vw 0px;
  padding: 8px 12px 9px;
  line-height: 1.3;
  font-family: "Noto Sans TC";
}
@media (max-width: 1024px) {
  .card__note {
    border-radius: 4.6875vw 0px;
  }
}

.card__price {
  width: 100%;
}
.card__price p {
  margin-bottom: 2px !important;
}
.card__price .price {
  padding-left: 10px;
  padding-right: 2px;
}
@media (max-width: 640px) {
  .card__price p {
    margin-bottom: 5px !important;
  }
  .card__price span.item {
    display: block;
  }
  .card__price span.item + .price {
    padding-left: 0;
  }
}

.card__infoList {
  display: flex;
  align-items: flex-start;
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
}
.card__infoList .card__note {
  min-width: 60px;
  flex: none;
}
@media (min-width: 640px) {
  .card__infoList .card__note + .card__price {
    margin-left: 1rem;
  }
}
@media (max-width: 640px) {
  .card__infoList .card__note {
    min-width: 100%;
    margin-bottom: 10px;
    padding-bottom: 8px;
  }
  .card__infoList .card__note br {
    display: none;
  }
}
@media (min-width: 900px) {
  .card__infoList {
    margin-bottom: 2rem;
  }
}
@media (max-width: 640px) {
  .card__infoList {
    flex-wrap: wrap;
  }
}

.mainbanner .img img {
  width: 100%;
}
.mainbanner .txt {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1450px;
  z-index: 1;
  color: #fff;
}
.mainbanner .txt-big {
  font-size: 36px;
  font-size: 2.25rem;
  margin-bottom: 10px;
}
.mainbanner .txt-small {
  font-size: 18px;
  font-size: 1.125rem;
}
@media (max-width: 1500px) {
  .mainbanner .txt {
    padding-left: 45px;
  }
}
@media (max-width: 810px) {
  .mainbanner .img img {
    height: 200px;
    object-fit: cover;
    object-position: center;
  }
  .mainbanner .txt {
    top: 50%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .mainbanner .txt-big {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

canvas {
  display: block;
  vertical-align: bottom;
}

#particles-js {
  position: absolute;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.content-title {
  border-bottom: 1px solid #b8b8b8;
}
.content-title .item {
  font-size: 20px;
  color: #000;
}
.content-title .item a,
.content-title .item span {
  position: relative;
  display: block;
  padding: 32.5px 0 28px;
}
.content-title .item:hover, .content-title .item.current {
  color: #0097d8;
}
.content-title .item:hover a::after,
.content-title .item:hover span::after, .content-title .item.current a::after,
.content-title .item.current span::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: #0097d8;
  position: absolute;
  bottom: 0;
}
.content-title .item ~ .item {
  margin-left: 30px;
}
.content-title .item ~ .item::before {
  content: "";
  display: block;
  width: 1px;
  height: 20px;
  background-color: #bfbfbf;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -6px;
}
.content-title .item ~ .item a,
.content-title .item ~ .item span {
  margin-left: 25px;
}
@media (max-width: 1500px) {
  .content-title .item {
    padding-left: 45px;
  }
  .content-title .item span {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 810px) {
  .content-title .item {
    padding-left: 30px;
  }
  .content-title .item a, .content-title .item span {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.image-slider {
  z-index: 2;
}
.image-slider .content {
  width: 100%;
  margin: auto;
  justify-content: center;
  flex-direction: row;
}
@media (min-width: 1101px) {
  .image-slider .content {
    max-width: 73.6842105263vw;
  }
}
@media (max-width: 1080px) {
  .image-slider .content {
    max-width: 89.4736842105vw;
  }
}
@media (max-width: 640px) {
  .image-slider .content {
    max-width: 100%;
    flex-direction: column;
    background: #f0f4f3;
    padding: 1rem;
  }
}
.image-slider .swiper-slide {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1101px) {
  .image-slider .swiper-slide .index-banner-wrapper {
    margin: 7.8947368421vw auto 18.4210526316vw;
  }
}
@media (min-width: 1100px) and (max-width: 1300px) {
  .image-slider .swiper-slide .index-banner-wrapper {
    margin-bottom: 21.0526315789vw;
  }
}
@media (max-width: 1100px) {
  .image-slider .swiper-slide {
    min-height: 53.8947368421vw;
  }
}
.image-slider .swiper-slide .img-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 36%;
}
@media (max-width: 640px) {
  .image-slider .swiper-slide .img-container {
    position: static;
    height: 200px;
  }
}
.image-slider .swiper-slide:nth-child(even) .content {
  justify-content: center;
}
.image-slider .swiper-slide:nth-child(odd) .content {
  justify-content: flex-start;
}
.image-slider .swiper-button-next, .image-slider .swiper-button-prev {
  color: #000;
}
@media (min-width: 1101px) {
  .image-slider .swiper-button-next, .image-slider .swiper-button-prev {
    width: 80px;
    height: 80px;
    color: #fff;
    background: rgba(0, 0, 0, 0.55);
  }
}
@media (max-width: 640px) {
  .image-slider .swiper-button-next, .image-slider .swiper-button-prev {
    top: 25%;
  }
}
@media (min-width: 1101px) {
  .image-slider .swiper-button-next {
    right: 8%;
  }
  .image-slider .swiper-button-prev {
    left: 8%;
  }
}
.image-slider .text-wrapper {
  overflow: hidden;
  display: inline-flex;
}
.image-slider .text-wrapper .text-inner .sub-title,
.image-slider .text-wrapper .text-inner .titleStyle,
.image-slider .text-wrapper .text-inner .title {
  transform: translateY(50vw);
  transition: all ease 1s;
}
.image-slider .swiper-slide-active.active .text-inner .sub-title,
.image-slider .swiper-slide.active.active .text-inner .titleStyle,
.image-slider .swiper-slide-active.active .text-inner .title {
  transform: translateY(0);
  opacity: 1;
}
.image-slider .swiper-slide.active .text-inner .sub-title {
  transition-delay: 0.05s;
}
.image-slider .swiper-slide.active .text-inner .titleStyle {
  transition-delay: 0.075s;
}
.image-slider .swiper-slide.active .text-inner .title {
  transition-delay: 0.1s;
}

.index {
  padding: 1rem;
}
@media (min-width: 1101px) {
  .index {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}
@media (max-width: 640px) {
  .index-banner img {
    height: 200px;
    object-fit: cover;
    object-position: center;
  }
}
.index-banner-title .en {
  color: #000;
  font-family: "Montserrat";
}
@media (min-width: 1101px) {
  .index-banner-title .en {
    font-size: 1.8947368421vw;
  }
}
.index-banner-title .titleStyle {
  margin: 1rem 0 1.5rem;
  font-weight: 100;
}
.index-banner-title .titleStyle::after {
  margin-left: 1rem;
}
@media (min-width: 1101px) {
  .index-banner-title .titleStyle {
    margin: 2rem 0 2.5rem;
    font-size: 1.5789473684vw;
  }
}
.index-banner-title .ch {
  color: transparent;
}
.index-banner-title .ch p {
  font-weight: 900;
  color: #000;
  text-shadow: 3px 3px #fff, -3px 3px #fff, 3px -3px #fff, 3px -3px #fff, 3px 3px 0px #fff;
  font-family: adobe-fan-heiti-std, sans-serif;
  letter-spacing: 2px;
}
@media (min-width: 1101px) {
  .index-banner-title .ch p {
    font-size: 2.6315789474vw;
  }
}
.index-banner-title .ch .space {
  padding-left: 12px;
  padding-right: 12px;
}
.index-banner-title.white .titleStyle::after {
  margin-left: 0;
}
@media (min-width: 1101px) {
  .index-banner-title.white .en,
.index-banner-title.white .titleStyle,
.index-banner-title.white .ch {
    color: #fff;
  }
  .index-banner-title.white .titleStyle {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .index-banner-title.white .titleStyle::after {
    flex: 0 1 145px;
    height: 2px;
    background-color: #fff;
  }
  .index-banner-title.white .ch p {
    text-shadow: none;
    color: #fff;
    font-size: 2.1052631579vw;
  }
}
@media (max-width: 640px) {
  .index-banner-title * {
    color: #374a6f !important;
  }
  .index-banner-title .sub-title {
    font-size: 3.90625vw;
  }
  .index-banner-title .titleStyle {
    font-size: 5.46875vw;
  }
  .index-banner-title .title {
    font-size: 6.25vw;
  }
}
.index-course {
  background-color: #eaecec;
}
.index-course .card__list {
  flex: 0 0 calc(50% - 16px);
  max-width: calc(50% - 16px);
}
.index-ourService {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url("../images/index-ourService-bg.jpg");
}
.index-ourService-list {
  justify-content: space-between;
  color: #fff;
}
.index-ourService-list .title {
  font-size: 36px;
  font-size: 2.25rem;
  margin-bottom: 20px;
}
.index-ourService-list .list {
  margin-bottom: 10px;
}
.index-ourService-list .list .item {
  position: relative;
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 5px;
  padding-left: 22px;
}
.index-ourService-list .list .item::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/icon-format.png");
  position: absolute;
  left: 0;
  top: 7px;
}
.index-ourService-list .block-title-box {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}
.index-ourService-list .block-title-box::before {
  width: 60px;
}

.news-detail-title {
  align-items: flex-start;
  flex-direction: column;
  border-top: #434343 3px solid;
  border-bottom: #a9a9a9 1px solid;
  padding: 1rem;
  margin-bottom: 1.5rem;
}
@media (max-width: 1024px) {
  .news-detail-title {
    flex-wrap: nowrap;
  }
}
.news-detail-txt {
  border: #d2d2d2 1px solid;
  padding: 1rem 2rem 2rem;
}
.news-detail-item {
  margin-bottom: 1rem;
}

.course-list {
  width: 100%;
}
.course-list .card__list {
  margin-bottom: 6.5rem;
}
@media (min-width: 1101px) {
  .course-list .card__list {
    padding: 0rem;
    margin-bottom: 4rem;
  }
}
@media (min-width: 1101px) {
  .course-list .card__info {
    padding-top: 1.25rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
@media (max-width: 1100px) {
  .course-list .card__image {
    margin-top: 0px;
  }
}
@media (min-width: 1101px) {
  .course-list .card__des {
    display: block;
    margin-top: 0px;
    margin-bottom: 0;
  }
}
@media (max-width: 1100px) {
  .course-list .card__des {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.course-list .card__btn {
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 1101px) {
  .course-list .card__btn {
    bottom: -6%;
  }
}

.video-list .card__list {
  margin-bottom: 1rem;
}

.venue_title {
  margin-bottom: 4rem;
}
.venue_title p {
  color: #040404;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 1101px) {
  .venue_title p {
    padding-right: 40px;
  }
}
.venue_title .tag-item {
  border-radius: 10px !important;
  font-size: 20px !important;
}
@media (max-width: 1100px) {
  .venue_title {
    margin-bottom: 1.25rem;
  }
  .venue_title .tag-item {
    font-size: 18px !important;
    margin-top: 0.75rem;
  }
}
.venue_item {
  border-width: 0 0 1px 0;
  border-color: #c9c9c9;
  border-style: solid;
  line-height: 1.5;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 1.5rem;
}
.venue_item p {
  margin-bottom: 0;
}
.venue_item .title {
  padding-left: 10px;
  padding-right: 1.5rem;
  font-size: 22px;
  color: #020202;
}
@media (max-width: 640px) {
  .venue_item p {
    margin-top: 5px;
  }
}
.venue_info p {
  margin-bottom: 0;
}
.venue_info .text {
  font-size: 18px;
  margin-bottom: 1rem;
}
.venue_info .text .icon {
  flex: 0 0 37px;
  margin-right: 10px;
}
@media (min-width: 1101px) {
  .venue_info .text .list {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
}
.venue_info_price .text {
  align-items: first baseline;
  border-width: 1px;
  border-color: #979797;
  border-style: dotted;
  border-radius: 20px;
  padding: 10px 20px;
}
.venue_info_price .text .list span.text-muted {
  padding-right: 5px;
}
.venue_info_price .text .list .priceNum {
  flex-grow: 1;
}
@media (min-width: 1101px) {
  .venue_info_price .text .list .priceNum {
    font-size: 1.0526315789vw;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .venue_info_price .text .list .priceNum {
    font-size: 1.1578947368vw;
  }
}
.venue_info_price .text .list .priceEvery {
  display: inline-flex;
  flex-flow: row wrap;
}
@media (min-width: 1101px) {
  .venue_info_price .text .list .priceEvery {
    font-size: 1.0526315789vw;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .venue_info_price .text .list .priceEvery {
    font-size: 1.1578947368vw;
  }
}
.venue_info_priceYear {
  justify-content: space-around;
}
.venue_info_priceYear .text {
  justify-content: center;
  border-width: 2px;
  border-color: #a5a5a5;
  border-style: dotted;
  border-radius: 20px;
  padding: 10px 12px;
  width: 33%;
}
.venue_info_priceYear .text:nth-last-child(2) {
  width: calc(33% - 10px);
  margin-left: 5px;
  margin-right: 5px;
}
.venue_info_priceYear .text.full {
  width: 100%;
  border-width: 2px;
  border-color: #ec8890;
  border-style: dotted;
}
.venue_info_priceYear .text.full .list {
  display: flex;
  justify-content: center;
  width: 100%;
}
.venue_info_priceYear .text.full .list p {
  padding: 0 5px;
}
@media (max-width: 640px) {
  .venue_info_priceYear .text {
    padding-left: 7px;
    padding-right: 7px;
  }
}
.venue_info_priceYear p.text-muted {
  font-size: 14px;
}
@media (min-width: 1101px) {
  .venue_info_priceYear p.text-muted {
    font-size: 0.8421052632vw;
  }
}
@media (min-width: 1100px) and (max-width: 1680px) {
  .venue_info_priceYear p.text-muted {
    font-size: 1.0526315789vw;
  }
}
@media (min-width: 1101px) {
  .venue_info_priceYear {
    padding: 0 0.5rem;
  }
}
.venue_info_traffic .text .list {
  display: block;
  flex: 1;
}
@media (min-width: 1101px) {
  .venue_info_traffic {
    padding-left: 1.5rem;
  }
}
.venue_content {
  font-size: 18px;
}
.venue_content .img {
  margin-bottom: 1rem;
}
.venue_content .info {
  display: flex;
  flex-flow: row wrap;
}
.venue_content .info .venue_item,
.venue_content .info .venue_info {
  width: 100%;
}
.venue_content .info .venue_item_list {
  width: 100%;
  margin-bottom: 1.5rem;
}
@media (min-width: 1101px) {
  .venue_content .info .venue_item_list {
    flex-basis: calc(50% - 10px);
    margin-right: 10px;
  }
  .venue_content .info .venue_item_list + .venue_item_list {
    margin-left: 10px;
    margin-right: 0;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .venue_content .info .venue_item_list {
    flex-basis: calc(47% - 10px);
  }
  .venue_content .info .venue_item_list + .venue_item_list {
    flex-basis: calc(53% - 10px);
  }
}
@media (min-width: 1101px) {
  .venue_content .img {
    flex-basis: 470px;
  }
  .venue_content .info {
    max-width: calc(100% - 470px);
    padding-left: 70px;
  }
}
@media (min-width: 1100px) and (max-width: 1680px) {
  .venue_content .img {
    flex-basis: 260px;
  }
  .venue_content .info {
    padding-left: 2rem;
    max-width: calc(100% - 260px);
  }
  .venue_content .info .venue_item:first-child .text {
    font-size: 1.0526315789vw;
  }
}
@media (min-width: 1100px) and (max-width: 1250px) {
  .venue_content .img {
    flex-basis: 200px;
  }
  .venue_content .info {
    padding-left: 2rem;
    max-width: calc(100% - 200px);
  }
}

.form-style .form-group {
  margin-bottom: 1.75rem;
}
.form-style .form-group > label {
  font-size: 18px;
}
@media (min-width: 1101px) {
  .form-style .form-group > label {
    flex: 0 0 150px;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .form-style .form-group > label {
    font-size: 16px;
  }
}
@media (min-width: 1101px) {
  .form-style .form-group {
    flex-wrap: nowrap;
  }
}
@media (max-width: 1100px) {
  .form-style .form-group-confirmPwd .form-control {
    width: calc(100% - 50px);
  }
}
.form-style .form-control {
  background-color: #fff;
  border-width: 1px;
  border-color: #9c9b9c;
  border-style: solid;
}
.form-style .form-input {
  justify-content: space-between;
  margin-top: 2rem;
  color: #777777;
  font-size: 16px;
}
.form-style .form-check, .form-style .form-link {
  flex: 1;
}
@media (max-width: 640px) {
  .form-style .form-check, .form-style .form-link {
    flex: none;
    line-height: 1;
  }
}
.form-style .form-check {
  padding-left: 0;
}
.form-style .form-link {
  text-align: right;
}
.form-style .btn_wrap {
  margin-top: 1.5rem;
}
.form-style .disabled {
  background-color: #e5eef0;
  border-color: #e5eef0;
}

.member-courseName {
  width: 100%;
  border-width: 1px;
  border-color: #b5b5b5;
  border-style: solid;
  padding: 10px 20px;
}
.member-title {
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin: 0.5rem 2rem 2rem;
}
.member-title .icon {
  margin-right: 15px;
}
@media (min-width: 1101px) {
  .member-title {
    font-size: 1.8947368421vw;
  }
}
@media (max-width: 1100px) {
  .member-title {
    margin: 1rem;
  }
  .member-title .icon img {
    max-width: 40px;
  }
}
.member-normal-content {
  padding-bottom: 0;
}
.member-normal-content .content .right article.content {
  padding: 0;
  margin-bottom: 0;
}
.member-form-content {
  padding: 2rem 1rem;
  background-color: #fff;
  overflow: hidden;
}
.member-form-content .form-group > label {
  flex-wrap: wrap;
}
@media (min-width: 1600px) {
  .member-form-content .form-group > label {
    flex: 0 0 185px;
  }
}
.member-form-content .form-group .form-check .radio_style {
  margin-top: 0 !important;
  margin-left: 0 !important;
}
@media (min-width: 1101px) {
  .member-form-content .form-group {
    flex-wrap: nowrap;
  }
}
.member-form-content .form-list {
  flex-wrap: nowrap;
}
.member-form-content .form-list .form-check {
  flex: none;
}
@media (min-width: 1101px) {
  .member-form-content .form-list .form-control {
    flex: 0 0 calc(100% - 150px);
  }
  .member-form-content .form-list .form-check {
    width: 150px;
    flex: none;
  }
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .member-form-content .form-col-name {
    max-width: 65%;
  }
  .member-form-content .form-col-id {
    max-width: 35%;
  }
  .member-form-content .form-col-id > label {
    flex: 0 0 90px;
  }
}
@media (max-width: 1100px) {
  .member-form-content {
    padding: 1rem;
  }
}
@media (max-width: 1100px) {
  .member-form-content .form-row-birth {
    margin-left: -5px;
    margin-right: -5px;
  }
  .member-form-content .form-row-birth .col {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.member-form-content .member_checkout_info {
  background: #eee;
}
.member-form-content .member_checkout_info .memberCart_form_style {
  width: 100%;
}
.member-form-content .member_checkout_info .memberCart_form_style .btn_style {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  font-size: 18px;
}
@media (min-width: 1101px) {
  .member-form-content .member_checkout_info .memberCart_form_style .btn_style {
    font-size: 0.9473684211vw;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .member-form-content .member_checkout_info .memberCart_form_style .btn_style {
    font-size: 1.1578947368vw;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
}
@media (min-width: 1101px) {
  .member-form-content .member_checkout_info .memberCart_form_head,
.member-form-content .member_checkout_info .memberCart_form_item tr {
    border: #eee 10px solid;
  }
}
.member-form-content .member_checkout_info .memberCart_form_head th,
.member-form-content .member_checkout_info .memberCart_form_item td {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}
.member-form-content .member_checkout_info .memberCart_form_head th {
  font-size: 16px;
  color: #7b7b7b;
  font-weight: 300;
}
@media (min-width: 1101px) {
  .member-form-content .member_checkout_info .memberCart_form_item tr:nth-child(even) {
    background-color: #f5f5f5;
  }
}
@media (max-width: 1100px) {
  .member-form-content .member_checkout_info .memberCart_form_item tr {
    background-color: #fff;
    border: #eee 10px solid;
    display: block;
  }
}
.member-form-content .member_checkout_info .memberCart_form_item tr td {
  color: #363636;
  font-size: 18px;
  background-color: #fff;
}
@media (max-width: 1100px) {
  .member-form-content .member_checkout_info .memberCart_form_item tr td {
    display: flex;
    align-items: center;
    width: 100% !important;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  .member-form-content .member_checkout_info .memberCart_form_item tr td::before {
    content: attr(data-title) ":";
    display: inline-block;
    min-width: 80px;
    margin-right: 1rem;
    margin-top: 0.15rem;
    color: #777;
  }
}
.member-form-content .member_checkout_info .memberCart_form_item tr td:first-child {
  color: #9b9a9a;
}
@media (max-width: 1100px) {
  .member-form-content .member_checkout_info .memberCart_form_head {
    display: none;
  }
}

/* -------------- login --------------*/
.login-container {
  width: 100%;
}
.login-grid {
  width: 100%;
  max-width: 650px;
  margin: 10px auto 40px;
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
.login-grid .form-style .form-group > label {
  position: relative;
  padding-left: 20px;
}
.login-grid .form-style .form-group > label::before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  background-color: #010101;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -3px;
  border-radius: 50%;
}

/* -------------- 內框用 --------------*/
.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 1101px) {
  .main-container {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
@media (max-width: 1100px) {
  .main-container {
    padding-top: 1rem;
  }
}
.main-aside {
  background-color: #eeeeee;
}
@media (min-width: 1101px) {
  .main-aside {
    width: 290px;
    flex-shrink: 0;
    margin-right: 10px;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .main-aside {
    width: 230px;
  }
}
.main-section {
  flex-grow: 1;
  max-width: 100%;
  padding: 1rem;
}
@media (min-width: 1601px) {
  .main-section {
    padding: 2.5rem 0 2.5rem 100px;
  }
}
@media (max-width: 1100px) {
  .main-section {
    padding-left: 0;
    padding-right: 0;
  }
}
.main-form-title {
  margin-bottom: 1em;
}
.main-form-title .icon {
  margin-right: 15px;
}
.main-form-title .title {
  font-size: 24px;
  color: #007894;
  letter-spacing: 1px;
  font-weight: 500;
}
.main-form-title .title span {
  margin-top: 5px;
  font-size: 16px;
  color: #007894;
  word-spacing: 1px;
  font-weight: normal;
  font-weight: 500;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  grid-gap: 20px;
}
.main-form-title .title span::before, .main-form-title .title span::after {
  content: " ";
  display: block;
  border-bottom: 1px solid #00b6e1;
  border-top: 1px solid #00b6e1;
  height: 5px;
}
@media (max-width: 1100px) {
  .main-form-title {
    margin-top: 1rem;
  }
}

.link-wrap {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.link-wrap > li {
  position: relative;
  border-bottom: #686767 1px solid;
}
.link-wrap > li > a {
  position: relative;
  display: block;
  color: #333;
  font-size: 18px;
  line-height: 1.3;
  padding: 1rem;
}
.link-wrap > li > a:hover, .link-wrap > li > a.active {
  color: #007894;
}
@media (min-width: 1101px) {
  .link-wrap > li > a {
    padding: 0.5rem 1rem 1rem 1rem;
  }
}
.link-wrap > li:hover:after {
  transform: rotate(180deg);
}
@media (min-width: 1101px) {
  .link-wrap > li::after {
    content: "";
    display: block;
    background-image: url("../images/icon-arrow-top.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 15px;
    height: 10px;
    position: absolute;
    right: 5px;
    top: 18px;
    transform: rotate(90deg);
    transition: 0.25s;
  }
}
@media (min-width: 1101px) {
  .link-wrap {
    flex-direction: column;
    padding: 1.5rem 2rem 3rem;
  }
  .link-wrap > li + li {
    margin-top: 0.5rem;
  }
}
@media (max-width: 1100px) {
  .link-wrap > li {
    flex: 1;
    text-align: center;
  }
  .link-wrap > li ~ li {
    border-left: none;
  }
  .link-wrap > li > a {
    text-align: center;
    font-size: 1rem;
  }
  .link-wrap > li > a.active {
    border-bottom: 2px solid #757575;
  }
}

.contact-bg {
  background-image: url("../images/bg-contact.jpg");
  padding-top: 120px;
  padding-bottom: 120px;
}

.contact-block {
  display: flex;
  position: relative;
  width: 100%;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  border: #c9c9c9 1px solid;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
@media (max-width: 1100px) {
  .contact-block {
    flex-direction: column;
  }
}
.contact-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media (min-width: 1101px) {
  .contact-info {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}
@media (max-width: 1100px) {
  .contact-info {
    flex-direction: column;
  }
}
.contact-info-list {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
@media (min-width: 1101px) {
  .contact-info-list {
    flex-basis: 350px;
  }
  .contact-info-list.add {
    flex-basis: 430px;
  }
  .contact-info-list.social {
    flex-basis: 100%;
  }
}
.contact-info-list .ci-icon {
  flex: none;
  width: 85px;
  height: 85px;
  border: 2px solid #dfdfdf;
  border-radius: 50%;
  margin-right: 20px;
  text-align: center;
  line-height: 85px;
}
.contact-info-list .ci-text {
  position: relative;
  flex: 1;
  font-size: 20px;
  color: #000;
  line-height: 30px;
  border-bottom: #c9c9c9 2px solid;
  padding-left: 10px;
  padding-bottom: 5px;
}
.contact-info-list .ci-text p {
  margin-bottom: 5px;
}
.contact-map .embed-responsive-4by3::before {
  padding-top: calc(100% + 100px);
}
@media (min-width: 1101px) {
  .contact-map {
    flex: 0 0 40%;
  }
}
.contact-form {
  padding: 1rem 0;
}
.contact-form legend {
  line-height: 1.5;
  font-size: 16px;
  font-size: 1rem;
  color: #5d5d5e;
  margin-bottom: 1rem;
}
.contact-form label {
  margin-bottom: 0;
  font-size: 16px;
  color: #5d5d5e;
}
.contact-form .form-control {
  border-color: #a8a7a7;
  outline: none;
  box-shadow: none;
  background-color: #fff;
  border-width: 0 0 1px 0;
  border-style: solid;
}
.contact-form .form-control:hover, .contact-form .form-control:focus {
  border-color: #000000;
}
.contact-form .form-group {
  display: flex;
  margin-bottom: 1.5rem;
}
@media (min-width: 1101px) {
  .contact-form .form-group label {
    flex: none;
    width: 90px;
    margin-right: 0;
  }
}
.contact-form .form-group.msg {
  align-items: flex-start;
}
.contact-form .form-group-sex {
  flex-direction: row !important;
  margin-bottom: 0;
}
@media (min-width: 1101px) {
  .contact-form .form-group-sex {
    padding-left: 2rem;
    flex-basis: 800px;
  }
}
@media (max-width: 1100px) {
  .contact-form .form-group-sex {
    margin-top: 0.5rem;
  }
}
@media (max-width: 810px) {
  .contact-form .form-group {
    flex-direction: column;
  }
}
@media (max-width: 640px) {
  .contact-form .btn-wrap {
    flex-direction: column;
  }
  .contact-form .btn-wrap li {
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 10px;
  }
}
@media (min-width: 1101px) {
  .contact-form {
    flex: 0 0 60%;
    padding-top: 0;
    padding-left: 2.75rem;
    padding: 0rem 3rem 1rem 2.75rem;
  }
}
@media (max-width: 1100px) {
  .contact {
    display: block;
  }
}
.contact-accordion {
  width: 100%;
  border-top: 2px solid #333333;
}
.contact-accordion .card {
  border: none;
}
.contact-accordion .card-header, .contact-accordion .card-body {
  padding-left: 0.75rem;
}
.contact-accordion .card-header {
  background-color: #fff;
}
.contact-accordion .card-body {
  display: flex;
  background-color: #eeeeee;
  padding-top: 0.75rem;
  padding-bottom: 1.75rem;
}
.contact-accordion .card-body .txt {
  color: #696969;
  padding-top: 5px;
  padding-left: 20px;
}
.contact-accordion .card .icon {
  flex: none;
}
.contact-accordion .card-item {
  display: flex;
}
.contact-accordion .card-item .btn {
  position: relative;
  width: 100%;
  text-align: left;
  padding-right: 4rem;
}
.contact-accordion .card-item .btn::after {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 25px;
  height: 19px;
  background-image: url("../images/icon-arrow-top.png");
  position: absolute;
  right: 0;
  top: 20px;
  transition: 0.25s;
}
.contact-accordion .card-item .btn.collapsed::after {
  transform: rotate(180deg);
}

.dowload-wrap {
  width: 100%;
  margin-bottom: 1rem;
}
.dowload-wrap p {
  margin-bottom: 0;
}
.dowload-wrap .btn {
  text-align: center;
  padding: 0.5rem 0.75rem;
  padding-right: 2rem;
  color: #8c8b8b;
  border-color: #8c8b8b;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  background-size: auto;
  background-image: url("../images/icon-download-g.png");
  font-size: 16px;
  font-size: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dowload-wrap .btn, .dowload-wrap .btn:hover {
  background-color: #fff;
}
.dowload-wrap .btn:hover {
  color: #000;
  border-color: #000;
  background-image: url("../images/icon-download-b.png");
}
.dowload-item, .dowload-list {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  text-align: center;
}
.dowload-item {
  font-size: 18px;
  font-size: 1.125rem;
  color: #5f5f5f;
  border-bottom: 2px solid #a7a7a7;
  justify-content: space-between;
  padding-top: 10px;
}
@media (max-width: 640px) {
  .dowload-item {
    display: none;
  }
}
.dowload-list {
  font-size: 20px;
  font-size: 1.25rem;
  color: #646464;
  line-height: 1.5;
}
.dowload-list li {
  justify-content: space-between;
  border-bottom: 2px solid #e1e1e1;
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
}
.dowload-list li .dowload-fileName {
  text-align: left !important;
  padding-left: 3rem;
}
@media (max-width: 640px) {
  .dowload-list {
    padding-left: 5px;
    padding-right: 5px;
  }
  .dowload-list li {
    display: block;
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .dowload-list li .dowload-fileName {
    padding-left: 0;
  }
  .dowload-list li > div {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
  .dowload-list li > div::before {
    content: attr(data-title);
    display: inline-block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 100;
    line-height: 1.1;
    margin: 2.5px;
    padding: 10px;
    text-align: center;
    color: white;
    background: #00a1c7;
    flex: 0 0 100px;
    margin-right: 10px;
  }
  .dowload-list li > div .btn {
    padding-top: 0.32rem;
    padding-bottom: 0.32rem;
  }
}
.dowload-num {
  flex: 0 0 65px;
}
.dowload-act {
  flex: 0 0 140px;
}
.dowload-fileName {
  flex: 1;
}

.animationItem {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
  transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}
.animationItem.animated {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}