.news{

    &-detail-title{
        @extend %setFlex-center;
        align-items: flex-start;
        flex-direction: column;
        border-top:#434343 3px solid;
        border-bottom:#a9a9a9 1px solid;
        padding:1rem;
        margin-bottom: 1.5rem;
        @include pad1024{
            flex-wrap: nowrap;
        }
    }

    &-detail-txt{
        border:#d2d2d2 1px solid;
        padding:1rem 2rem 2rem;
    }

    &-detail-item{
        margin-bottom: 1rem;
    }
}