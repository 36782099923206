.venue{
    // 
    &_title{
        @extend %setFlex-center;
        margin-bottom: 4rem;
        p{
            color: #040404;
            font-size: 20px;
            margin-top: 0;
            margin-bottom: 0;
            @include setMinRwd{
                padding-right: 40px;
            }
        }
        .tag-item{
            border-radius: 10px !important;
            font-size: 20px !important;
        }

        @include setMaxRwd{
            margin-bottom: 1.25rem;
            .tag-item{
                font-size: 18px !important;
                margin-top: 0.75rem;
            }
        }
    }

    &_item{
        @extend %setFlex-center;
        @include border(0 0 1px 0,#c9c9c9,solid);
        line-height: 1.5;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 1.5rem;
        p{
            margin-bottom: 0;
        }
        .title{
            padding-left: 10px;
            padding-right: 1.5rem;
            font-size:22px;
            color:#020202;
        }
        @include mobile{
            p{
                margin-top: 5px;
            }
        }
    }

    &_info{
        p{
            margin-bottom: 0;
        }
        .text{
            @extend %setFlex;
            font-size: 18px;
            margin-bottom: 1rem;
            .icon{
                flex: 0 0 37px;
                margin-right: 10px;
            }
            .list{
                @include setMinRwd{
                    flex: 1;
                    display: inline-flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }

        // 價格
        &_price{
            .text{
                align-items: first baseline;
                @include border(1px,#979797,dotted);
                @include borderRadius(20);
                padding: 10px 20px;
                .list{
                    span.text-muted{
                        padding-right: 5px;
                    }

                    .priceNum{
                        flex-grow: 1;
                        @include setMinRwd{
                            font-size: get-vw(20px);
                        }
                        @include setWidthRwd(1100px,1600px){
                            font-size: get-vw(22px)
                        }
                    }

                    .priceEvery{
                        display: inline-flex;
                        flex-flow: row wrap;
                        @include setMinRwd{
                            font-size: get-vw(20px);
                        }
                        @include setWidthRwd(1100px,1600px){
                            font-size: get-vw(22px)
                        }
                    }
                }
            }
        }

        // 年專案
        .font-Europa{
            @extend %Europa-Bold;
        }
        
        &_priceYear{
            @extend %setFlex;
            justify-content: space-around;
            .text{
                justify-content: center;
                @include border(2px, #a5a5a5, dotted);
                @include borderRadius(20);
                padding: 10px 12px;

                width: 33%;
                &:nth-last-child(2){
                    width: calc(33% - 10px);
                    margin-left: 5px;
                    margin-right: 5px;
                }

                &.full{
                    width: 100%;
                    @include border(2px, #ec8890, dotted);
                    .list{
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        p{
                            padding: 0 5px;
                        }
                    }
                }

                @include mobile{
                    padding-left: 7px;
                    padding-right: 7px;
                }
            }

            p.text-muted{
                font-size:14px;
                @include setMinRwd{
                    font-size: get-vw(16px)
                }
                @include setWidthRwd(1100px,1680px){
                    font-size: get-vw(20px)
                }
            }

            @include setMinRwd{
                padding: 0 0.5rem;
            }
        }

        // 交通
        &_traffic{
            .text{
                .list{
                    display: block;
                    flex: 1;
                }
            }
            @include setMinRwd{
                padding-left: 1.5rem;
            }
        }
    }

    &_content{
        @extend %setFlex;
        font-size: 18px;
        .img{
            margin-bottom: 1rem;
        }

        .info{
            display: flex;
            flex-flow: row wrap;
            .venue_item,
            .venue_info{
                width: 100%;
            }
            .venue_item_list{
                width: 100%;
                margin-bottom: 1.5rem;
                @include setMinRwd{
                    flex-basis: calc(50% - 10px);
                    margin-right: 10px;
                    &+.venue_item_list{
                        margin-left: 10px;
                        margin-right: 0;
                    }
                }
                @include setWidthRwd(1100px,1600px){
                    flex-basis: calc(47% - 10px);
                    &+.venue_item_list{
                        flex-basis: calc(53% - 10px);
                    }
                }
            }
        }

        @include setMinRwd{
            .img{
                flex-basis: 470px;
            }
            .info{
                max-width: calc(100% - 470px);
                padding-left: 70px;
            }
        }
        @include setWidthRwd(1100px,1680px){
            .img{
                flex-basis: 260px;
            }
            .info{
                padding-left: 2rem;
                max-width: calc(100% - 260px);
                .venue_item{
                    &:first-child{
                        .text{
                            font-size: get-vw(20px);
                        }
                    }
                }
            }
        }
        @include setWidthRwd(1100px,1250px){
            .img{
                flex-basis: 200px;
            }
            .info{
                padding-left: 2rem;
                max-width: calc(100% - 200px);
            }
        }
    }

}