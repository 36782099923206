// 聯絡我們
.contact-bg{
    @extend %setBack;
    background-image: url('../images/bg-contact.jpg');
    padding-top: 120px;
    padding-bottom: 120px;
}

.contact{
    &-block {
        display: flex;
        position: relative;
        width: 100%;
        padding: 1.5rem 1.5rem 0.5rem 1.5rem;
        border:#c9c9c9 1px solid;
        box-shadow: 0 0 10px rgba(0,0,0,.15);
        @include setMaxRwd{
            flex-direction: column;
        }
    }
    
    &-info{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        margin-bottom: 1rem;
        @include setMinRwd{
            margin-top: 2rem;
            margin-bottom: 3rem;
        }
        @include setMaxRwd{
            flex-direction: column;
        }

        &-list{
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            @include setMinRwd{
                flex-basis: 350px;

                &.add{
                    flex-basis: 430px;
                }

                &.social{
                    flex-basis: 100%;
                }
            }

            .ci-icon {
                flex: none;
                width: 85px;
                height: 85px;
                border: 2px solid #dfdfdf;
                border-radius: 50%;
                margin-right: 20px;
                text-align: center;
                line-height: 85px;
            }
            
            .ci-text {
                position: relative;
                flex: 1;
                font-size: 20px;
                color: #000;
                line-height: 30px;
                border-bottom: #c9c9c9 2px solid;
                padding-left: 10px;
                padding-bottom: 5px;
                p{
                    margin-bottom: 5px;
                }
            }
        }
    }

    &-map{
        .embed-responsive-4by3{
            &::before {
                padding-top: calc(100% + 100px);
            }
        }
        @include setMinRwd{
            flex: 0 0 40%;
        }
    }

    &-form {
        padding: 1rem 0;
        legend{
            line-height:1.5;
            @include fontSize(16px);
            color:#5d5d5e;
            margin-bottom: 1rem;
        }

        label{
            margin-bottom: 0;
            font-size: 16px;
            color:#5d5d5e
        }

        .form-control{
            border-color: #a8a7a7;
            outline: none;
            box-shadow: none;
            background-color: #fff;
            border-width:0 0 1px 0;
            border-style: solid;
            &:hover,
            &:focus{
                border-color: #000000;
            }
        }

        .form-group{
            display: flex;
            margin-bottom: 1.5rem;
            label{
                @include setMinRwd{
                    flex: none;
                    width: 90px;
                    margin-right: 0;
                }
            }

            &.msg{
                align-items: flex-start;
            }

            &-sex{
                flex-direction: row !important;
                margin-bottom: 0;
                @include setMinRwd{
                    padding-left: 2rem;
                    flex-basis: 800px;
                }
                @include setMaxRwd{
                    margin-top: 0.5rem;
                }
            }

            @include pad{
                flex-direction: column
            }
        }

        .btn-wrap{
            @extend %setFlex;
            @include mobile{
                flex-direction: column;
                li{
                    width: 100%;
                    margin-left: 0 !important;
                    margin-bottom: 10px;
                }
            }
        }

        @include setMinRwd{
            flex:0 0 60%;
            padding-top: 0;
            padding-left: 2.75rem;
            padding: 0rem 3rem 1rem 2.75rem;
        }
        @include pad1024{}
    }

    @include setMaxRwd{
        display: block;
    }

    // 常見問題的QA
    &-accordion{
        width: 100%;
        border-top: 2px solid #333333;
        .card{
            border:none;
            
            &-header,
            &-body {
                padding-left: 0.75rem;
            }

            &-header{
                background-color: #fff;
            }

            &-body {
                display: flex;
                background-color: #eeeeee;
                padding-top: 0.75rem;
                padding-bottom: 1.75rem;
                .txt{
                    color:#696969;
                    padding-top: 5px;
                    padding-left: 20px;
                }
            }

            .icon{
                flex: none;
            }

            &-item{
                display: flex;
                .btn{
                    position: relative;
                    width: 100%;
                    text-align: left;
                    padding-right: 4rem;
                    &::after{
                        content: '';
                        display: block;
                        @include setBackSet(center,contain);
                        @include setSize(25px,19px);
                        background-image: url('../images/icon-arrow-top.png');
                        position: absolute;
                        right: 0;
                        top: 20px;
                        transition: 0.25s;
                    }
                    &.collapsed{
                        &::after{
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
}