.card-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .card {
    width: auto;
    height: auto;
    margin: 2px 8px 10px;
    box-shadow:none;
    border-radius: 0 !important;

    p{
      position: relative;
      z-index: 3;
      margin: 0;
    }

    &__body {
      padding: .5rem 0;
      line-height: 1.5;
    }

    &-image-top{
      margin-bottom: 0.5rem;
    }

    &__image {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
      @include setMaxRwd{
        margin-top: 10px;
      }
    }

    &__info{
      width: 100%;
      &__image{
        margin-left: 10px;
      }
    }

    &__title {
      @include fontSize(20px);
      margin-bottom: 0.75rem;
      @include setMinRwd{
        &.big{
          font-size: get-vw(25px);
          margin-top: 1.25rem;
          margin-bottom: 2rem;
        }
      }
    }

    &__caption{
      @extend %setFlex;
      justify-content: space-between
    }

    &__time{
      @include fontSize(16px);
      color:#666666;
      font-family: $RobotoThin;
    }

    &__link{
      position: static !important;
      display: block;
      width: 40px;
      height: 20px;
      @include setBackSet(center,contain);
      background-image: url('../images/arrowAll_left.png');
      .txtHide{
        color:$colorMain;
        position: absolute;
        right: 15%;
        top: -20%;
        opacity: 0;
        visibility: hidden;
        transition: 0.25s;
      }
    }

    &__des{
      margin-top: 5px;
      margin-bottom: 20px;
      .icon{
        position: absolute;
        left: 0;
        & + .time,
        & + .txt{
          display: inline-block;
          padding-left: 2rem;
        }
      }
    }

    &__href{
      padding-bottom: 8px;
      border-bottom: #000 1px solid;
      .icon{
        vertical-align: top;
      }
    }
    
    &__list{
      align-items: flex-start;
      flex: 0 0 calc((100% / 4) - 16px);
      max-width: calc((100% / 4) - 16px);
      padding: 1rem;
      &.horizontal{
        .card__info{
          flex:1;
        }
      }

      @include setMinRwd{
        padding: 2rem;

        // 橫向
        &.horizontal{
          flex-direction: row;
          align-items: center;
          .card__info{
            & + .card__image{
              margin-left: 10px;
            }
          }
        }

        // 內頁用
        &.main{
          flex: 0 0 calc(33% - 16px);
          max-width: calc(33% - 16px);
        }
      }
    }

    &__btn{
      text-align: center;
    }

    &:hover{
      border-color: $colorMain;
      @include setMinRwd{
        .card__link{
          background-image: url('../images/arrowAll_left_active.png');
          .txtHide{
            opacity: 1;
            visibility:visible;
          }
        }
        .card__href{
          color: $colorMain;
        }
      }
    }
  }

  &.main{
    width: 100%;
    @include setMinRwd{
      justify-content: flex-start;
    }
  }

  @include pad{
    justify-content: flex-start;
    margin:1rem 0;
    .card{
      margin-left: 2px;
      margin-right: 2px;
      margin-bottom: 0.5rem !important;
      max-width: calc((100% / 2) - 4px) !important;
      flex:0 0 calc((100% / 2) - 4px) !important;
    }
  }

  @include mobile{
    .card{
      &__title {
        font-size: get-vw-mobile(25px);
      }
      &__caption {
        font-size: get-vw-mobile(20px);
      }
    }
  }
}

.txtMin{
  min-height: 65px;
  overflow: hidden;
}

// 另類樣式
.price{
  color:$colorItem;
  @include fontSize(19px)
}

.card__note{
  text-align: center;
  color:$colorMain;
  background-color: #aed5d7;
  border-radius: get-vw(20px) 0px;
  padding: 8px 12px 9px;
  line-height: 1.3;
  font-family: $fontNotosanc-tc;
  @include pad1024{
    border-radius: get-vw-mobile(30px) 0px;
  }
}

.card__price{
  width: 100%;
  p{
    margin-bottom: 2px !important;
  }

  .price{
    padding-left: 10px;
    padding-right: 2px;
  }

  @include mobile{
    p{
      margin-bottom: 5px !important;
    }
    span.item{
      display: block;
      &+.price{
        padding-left: 0;
      }
    }
  }

}

.card__infoList{
  display: flex;
  align-items:flex-start;
  margin-top: 2.5rem;
  margin-bottom:0.5rem;
  .card__note{
    min-width: 60px;
    flex:none;
    @include setStyleMinRwd(640px){
      &+.card__price{
        margin-left: 1rem;
      }
    }
    @include mobile{
      min-width: 100%;
      margin-bottom: 10px;
      padding-bottom: 8px;
      br{
        display: none;
      }
    }
  }
  
  @include setStyleMinRwd(900px){
    margin-bottom: 2rem;
  }

  @include mobile{
    flex-wrap: wrap;
  }
}
  